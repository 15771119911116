.container-carte{
    @include container;
    margin-bottom: 100px;
    #map{
        position: relative;
        display: block;
        width: 100%;
        aspect-ratio: 2/1;
        margin-top: 60px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);
        @include large{
            height: 90vh;
        }
        .mapboxgl-ctrl-attrib-inner{
            a{
                font-size: 10px;
            }
        }
        .mapboxgl-popup{
            z-index: 1;
        }
        .mapboxgl-marker{
            cursor: pointer;
        }
        .mapboxgl-popup-content{
            background-color: $blueWhiteColor;
            border-radius: 5px;
            text-align: center;
        }
        .mapboxgl-popup-tip{
            border-top-color:  $blueWhiteColor;
        }
        .container-slider-carte{
            width: 35%;
            height: 85%;
            overflow-y: hidden;
            position: absolute;
            background-color: $primaryColor;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            @include desktop-xl{
                width: 40%;
                @include large{
                        width: 100%;
                        height: 40%;
                        top: 0;
                        transform: unset;
                }
            }
        }
        ul{

            display: block;
            padding:20px 40px;
            width: 90%;
            .active{
                opacity: 1;
            }
            li{
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #fff;
                opacity: 0.4;
                padding: 10px 0;
                width: fit-content;
                width: 85%;
                @include transition;
                @include desktop-xl{
                    width: 95%;
                    
                }
                &:hover{
                    opacity: 1;
                }
                h2{
                    color: #fff;
                    font-size: clamp(18px, 3vw, 20px);
                    padding: 10px 0;
                    width: fit-content;
                }
                a,span{
                    color: #fff;
                    font-size: clamp(12px, 3vw, 14px);
                    padding: 1px;
                    width: fit-content;
                    

                    &:last-child{
                        padding-top: 12px;
                        text-decoration: underline !important;
                    }
                }
                span{
                    text-decoration: underline;
                }
            }
        }  
    }
    .cta{
        width: 100%;
        background-color: $primaryColor;
        padding:10px 20px;
        display: flex;
        justify-content: center;
        @include tablet{
            flex-direction: column;
        }
        a{
            margin: 10px 20px;
            display: block;
            @include tablet{
                margin: 10px auto;
            }
        }
    }
}