.iframe{
    margin-top: 50px;
}

.container-offres{
    position: relative;
    color: white;
    height: fit-content;
    padding: 80px 0;

    @include tablet{
        padding-top: 40px;
    }

    h2{
       color: $primaryColor;
       margin-bottom: 70px;
       text-align: center;
    }

    .carre{
        position: absolute;
        background-color: $primaryColor;
        bottom: 45px;
        height: 77%;
        width: 45%;
        z-index: -1;
        @include desktop-xxl{
            height: 72%;
        }
        @include desktop{
            width: 50%;
            height: 75%;
        }

        @include large{
            width: 60%;

            @include tablet{
                width: 70vw;
                height: 74%;
                bottom: 60px;
                
                @include smartphone{
                    width: 80vw;
                    height: 80%;
                }
            }
        }
    }
    .container-slider-offres{
        width: 73%;

        @include desktop-xxl{
            width: 65%;

            @include desktop{
                width:63%;

                @include large{
                    width:42%;
                }
            }
        }
    }
    .slider-offres{
        @include container;
        position: relative;
        height: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .content-offres{
            @include container;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: -10px;

            .content-text{
                width: fit-content;
                display: flex;
                flex-direction: column;
                max-width: 400px; 

                @include desktop{
                    max-width: 350px;
                }             

                @include smartphone{
                    width: 50vw !important;
                }

                p{
                    margin: 40px 0;
                    max-width: 350px;
                    
                }
                
                h2{
                    text-align: left !important;
                    color: white;
                    margin-bottom: 20px !important;
                }

                .btn{
                    margin: 0px;
                    margin-top: 20px ;
                    border: 1px solid white;
                    display: block;
                }

                .slick-slide{
                    height: fit-content;
                    opacity: 0;
                    @include transition;
                    transition-delay: 300ms;
                }
                .slick-current{
                    opacity: 1;
                }
            }
            
        }

        .content-img{
            display: flex;
                
                @include smartphone{
                    padding-bottom: 40px;
                    z-index: -1;
                }

            .img{
                width: 30%;
                aspect-ratio: 1/1.55;
                margin: 10px;
                background-size: cover;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                overflow: hidden;

                @include smartphone{
                    aspect-ratio: 1/2.2;
                    background-position: center;
                    margin-bottom: 138px;
                }

                .content-info{
                    transition: 0.5s ease-in-out;
                    z-index: 2;
                    text-align: center;

                    h2{
                        color: #fff;
                        margin-bottom: 10px;
                        font-weight: 300 !important;
                        font-size: clamp(20px, 1.6vw, 26px);
                    }

                    h3{
                        text-transform: capitalize;
                        font-weight: 300 !important;
                        font-size: clamp(20px, 1.6vw, 26px);
                    }

                }
            }

            .slick-slide{
                &:before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    background: rgb(0,0,0);
                    background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);                    transition: 0.5s ease-in-out;
                    z-index: 1; 
                }

                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    background-color: $primaryColor;
                    opacity: 0.6;
                    transition: 0.5s ease-in-out;
                }
            }

            .slick-current{

                &:after{
                    height: 0%;
                    opacity: 0;
                }
                &:before{
                    height: 0%;
                    opacity: 0;
                }

                .content-info{
                    transform: translateY(50vh);
                    opacity: 0;
                }
            }
        }
    }
}