

  /*  span{
        font-size: clamp(16px,3vw,18px);
        font-style: italic;
    }*/
    form{
        label{
            display: inline-grid;
        }
    }
    .content-tendances{
        display: flex;
        @include container;
        
    }


.content{
    background-color: white;
    margin: 0px 20px;
    width: 100%;
    height: 100%;
    //min-height: 70vh;
    max-height: 500px;

    .content-img{
        overflow: hidden;
        height: 55%;
        .img{
            width: 100%;
            aspect-ratio: 1.5/1;
            background-size: cover;
            //@include transition;
            vertical-align: middle;
            height: 100%;
            
            h2{
                color: white;
                background-color: rgba(0, 99, 163,0.7);
              //   background-color: rgba(192, 21, 101, 0.7);
                font-size: large;
                height: 60px;
                text-align: center;
                line-height: 9vh;
                opacity: 0.7;
               
            }

        }
    }

    &:hover{
        .content-img{
            .img{
                transform: scale(1.1);
            }
        }
    }
    .content-text{
        //padding: 7vh 1vw;
        text-align: left;
        padding-left: 3vw;
        
        position:relative;
        height:25%;
        ul{
            margin-top: 4VH;
            list-style-type: disc;
            height:100%;
            li{
            width:100%;
            }
        }

        @include desktop-xl{
            padding: 20px 20px 40px 20px;
        }

        h3{
            color: $primaryColor;
        }
        p{
            margin: 20px 0;
            margin-bottom: 40px;
        }

           /* div{
                display: table-cell;
                vertical-align: bottom;
                padding: 0px 15px;
                width:50%;
                position: relative;
                vertical-align: bottom;
            }*/
        }

       
        .logo{
            width:80%
        }

    .duoColonne{
        display: inline-block;
        columns: 2;
        height: 20%;
        padding-bottom:2,5vh;
       // padding-top: 10vh;
        width:100%;
        text-align: bottom;
        //overflow: hidden;
        
        .col-right{
            //margin-left:40%;
            width:100%;
        }
     

    }

}
