.container-intro-inspirer{
    span{
        font-family: 'Cinderella', arial !important;
    }
}
.carnet{
    @include container;

    h2{
        text-align: center;
    }
    .container-carnet{
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        height: 700px;

        @include smartphone{
            flex-direction: column-reverse;
            height: auto;
            margin-top: 30px;
        }
    }
    .container-slider{
        width: 75%;
        position: relative;
        @include large{
            width: 58%;

            @include smartphone{
                width: 100%;
            }
        }
    }
    .slider-carnet{
        width: 100%;
    }
    .slick-track{
        padding-bottom: 20px;
    }
    .slide{
        width: calc(90vw/4 - 50px);
        margin: 5px;

        @include smartphone{
            margin-top: 0px;
        }
        .cover{
            width: 100%;
            height: 350px;
            background-size: cover;
            z-index: -9999;
            position: relative;

            @include smartphone{
                width: 100%;
            }
        }
        .card{
            position: relative;
            display: block;
            width: 90%;
            margin: auto;
            padding:40px 30px;
            padding-top: 30px;
            color: $primaryColor;
            margin-top: -150px;

            @include smartphone{
                width: 95%;
                margin-top: -100px;
                padding: 20px 15px;
            }
            .info{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                span{
                    font-size: clamp(16px,3vw,18px);
                }
                img{
                    margin-right: 10px;
                    border-radius: 50%;
                    width: 100px;
                    aspect-ratio: 1/1;
                } 
            }
            p{
                min-height: 135px;
            }
            .btn{
                display: block;
                margin-left: 0;
                margin-top: 30px;

                @include smartphone{
                    margin-top: 20px;
                }
            }
            &:after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #fff;
                z-index: -1;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);
            }
            &:before{
                content: "‘’";
                font-family: 'Abril Display';
                font-size: 150px;
                position: absolute;
                bottom: 0;
                right: 0px;
                width: 55px;
                height: 50px;
                overflow: hidden;

                 @include smartphone{
                    width: 80px;
                    height: 75px;
                 }
            }
        }
        
    }
    .dropdown-carnet-wrapper {
        position: relative;
        width: 22%; 
        height: 625px;
        overflow: hidden; /* Cache le débordement */
        background-color: $blueWhiteColor;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);

        @include large{
            width: 40%;

            @include smartphone{
                width: 97.5%;
                height: 200px;
                margin: auto;
                margin-bottom: 0px;
                box-shadow: 0px -25px 12px -12px rgba(0,0,0,0.29) inset;
            }
        }
  
    }
      .dropdown-carnet {
        position: relative;
        width: 98%;
        padding: 20px 25px;
        height: 600px;
        overflow-y: scroll;
        margin-right: -10px; /* Décale le contenu interne de 10px à gauche */
      
        @include smartphone{
            height: 200px;
        }
        &::-webkit-scrollbar {
          width: 10px;
        }
      
        &::-webkit-scrollbar-track {
          margin: 10px;
        }
      
        &::-webkit-scrollbar-thumb {
          background: #fff;
          border-radius: 20px;
        }
      
        &::-webkit-scrollbar-thumb:hover {
          background: #fff;
          border-radius: 20px;

        }
      
      
        li {
          padding:2px 10px;
          cursor: pointer;
          @include transition;

          p {
            color: $primaryColor;
            text-transform: uppercase;  
            @include transition;
 
          }
          a{
            color: $primaryColor;
            text-decoration: underline !important;
            font-size: clamp(16px, 3vw, 14px);
            display: none;
            opacity: 0;
            @include transition;

            }
          &:hover{
            background-color: #fff; 

            p{
                font-weight: $bold;
            }
            a{
                display: block;
                opacity: 1;
            }
          }
        }
      }
      .controller-carnet{
        position: absolute;
        width: 100%;
        top:20%;
        display: flex;
        justify-content: space-between;

        .prev,.next{
            background-color: #fff;
            margin: 10px;
            border: none;

            &:hover{
                background-color: $primaryColor;
            }
        }

      }
}