/* ----------------------------------------------------------------
	Fonts
-----------------------------------------------------------------*/

@import url("https://use.typekit.net/teo5qrt.css");

//-------------------- Import fonts --------------------//
@font-face{
    font-family: 'Work Sans';
    src: url('../fonts/WorkSans.ttf');
        font-weight: 100;
        font-style: normal;
}
@font-face{
    font-family: 'Abril Display';
    src: url('../fonts/Abril_Display.otf');
        font-weight: 300;
        font-style: normal;
}
@font-face{
    font-family: 'MoonTime';
    src: url('../fonts/MoonTime.ttf');
        font-weight: 100;
        font-style: normal;
}
@font-face{
    font-family: 'OoohBaby-Regular';
    src: url('../fonts/OoohBaby-Regular.ttf');
        font-weight: 100;
        font-style: normal;
}
@font-face{
    font-family: 'Cinderella';
    src: url('../fonts/Cinderella.ttf');
        font-weight: 100;
        font-style: normal;
}
h1{
    color: $primaryColor;
}
h1,h2,h3,h4,h5,h6 {
    line-height: 137%;
    letter-spacing: 0.022em;
    font-weight: 700;
}

h2{font-size: clamp(20px, 3vw, 26px);text-transform: uppercase;color: $primaryColor;} 
p{
    font-size: clamp(16px, 3vw, 18px);
    line-height: 150%;
}
a{font-size: clamp(16px, 2vw , 24px);}
span{font-size: clamp(16px, 2vw , 24px);}