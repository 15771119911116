footer{
    .newsletter{
        background-color: $blueWhiteColor;
        padding: $padding;

        .content-newsletter{
            @include container;
            display: flex;
            align-items: center;
            gap: 75px;

            @include large{
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }

            .container-content-newsletter{
                width: 60%;

                @include large{
                    width: 90%;
                }
                h2{
                    color: $primaryColor;
                    font-weight: $bold;
                    text-transform: uppercase;
                    max-width: 600px;
                    margin-left: auto;

                    @include large{
                        font-size: 1.5em;
                        margin-left: unset;

                        @include tablet{
                            font-size: 1.2em;
                        }
                    }
                }
            }

            .input-email{
                display: flex;
                width: 40%;

                @include large{
                    width: 80%;
                    justify-content: flex-start;
                }
                input{
                    display: block;
                    width: 240px;
                    padding: 20px;
                    border: none;

                    &::placeholder{
                        color: $primaryColor;
                        font-size: 16px;
                        font-weight: $regular;
                    }

                    &:last-child{
                        width: 60px;
                        background-color: $primaryColor;
                        cursor: pointer;
                        background-size: 60%;
                        background-position: 50% 20%;
                        background-repeat: no-repeat;
                        @include transition;
                        
                        &:hover{
                            background-size: 70%;
                            background-position: 50% 0%;
                        }
                    }
                }
            }
        }
    }

    .content-footer{
        display: flex;
        background-color: $primaryColor;
        padding: 50px 0;
        .container-content-footer{
            @include container;
            display: flex;
            justify-content: space-between;
            @include desktop-xl{
                flex-wrap: wrap;
                justify-content: space-around;
                @include tablet{
                    justify-content: flex-start;
                }
            }
            .informations-footer{
                width: 30%;
                .logo-footer{
                    @include cover;
                    display: block;
                    height: 0;
                    padding-bottom: 10%;
                    width: 100%;
                    margin-bottom: 30px;
                    @include smartphone{
                        width: 90%;
                    }
                }

                p{
                    color: $whiteColor;
                    margin-bottom: 40px;
                    @include desktop{
                        width: 35vw;
                        @include tablet{
                            width: 60vw;
                            @include smartphone{
                                width: 75vw;
                            }
                        }
                    }

                }
                .btn{
                    margin-left:0px;
                    background-color: #fff;
                    color: $primaryColor;
                    display: block;
                    &:hover{
                        background-color: $primaryColor;
                        color: #fff;
                        border:1px solid #fff;
                    }
                }
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                margin-top: 40px;
                margin-bottom: 20px;
                @include desktop-xl{
                    width: 50vw;
                    @include desktop{
                        @include large{
                            margin-top: 0px;
                        }
                        @include tablet{
                            margin:0 5em;
                            width: 100vw;
                            margin-top: 60px;
                            margin-bottom: 40px;
                            margin-left: 0;
                        }
                    }
                }
                li {
                    width: fit-content;
                    padding: 7px;
                    min-width: 40%;
                    @include tablet{
                        min-width: 260px;
                    }
                    @include smartphone{
                        min-width: 230px;
                    }
                    a {
                        color: $whiteColor;
                        text-transform: uppercase;
                        font-size: 20px;
                        font-weight: $bold;
                        @include tablet{
                            font-size: 16px;
                        }
                    }
                }
            }
            .nav-footer{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 10%;
                @include large{
                    margin-left: 10px;
                    @include tablet{
                        margin-left: 0;
                    }
                
                }
                .social{
                    display: flex;
                    a{
                        position: relative;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #fff;
                        border-radius: 50%;
                        margin-right: 10px;
                        @include transition;
                        &:before{
                            content: "";
                            display: block;
                            height: 0px;
                            width: 0px;
                            border-radius: 50%;
                            background-color: #fff;
                            position: absolute;
                            @include transition;
                            z-index: 9;
        
                        }
                        svg{
                            z-index: 9;
                            @include transition;
                        }
                        &:hover{
                            &:before{
                                width: 33px;
                                height: 33px;
                            }
                            path{
                                fill: $primaryColor;
                            }
                        }
                    }
                }
            }
            .content-folder{
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: #fff;
                position: relative;
                margin-left: 40px;
                padding: 20px;
                padding-left: 110px;
                margin-top: 40px;
                @include desktop-xl{
                    margin-top: 100px;
                    margin-bottom: 50px;

                    @include tablet{
                        margin-left: 80px;

                        @include smartphone{
                            padding: 15px;
                            margin-left: 0;
                            margin-top: 120px;
                            margin-bottom: 0px;
                            max-width: 90vw;
                        }
                    }

                }
                &:hover{
                    .img_2{   
                        transform:  translate(-30px, -50%) rotate(-5deg);
                    }
                    .img_3{
                        transform:  translate(-60px, -50%) rotate(-5deg);
                    }                    
                }
                .img_1{
                    width: 160px;
                    aspect-ratio: 1.4/2;
                    background-size: cover;
                    position: absolute;
                    left: -75px;
                    top: 50%;
                    transform: translateY(-50%) rotate(-5deg);
                    box-shadow: 10px 10px 18px 5px rgba(0,0,0,0.14);
                    z-index: 3;
                    @include smartphone{
                        width: 100px;
                        left: -5px;
                        top: 0px;
                    }
                }
                .img_2{
                    position: absolute;
                    width: 160px;
                    left: -75px;
                    aspect-ratio: 1.4/2;
                    background-size: cover;
                    top: 50%;
                    transform: translateY(-50%) rotate(-5deg);
                    z-index: 2;
                    @include transition;
                    @include smartphone{
                        width: 100px;
                        left: -5px;
                        top: 0px;
                    }
                }
                .img_3{
                    position: absolute;
                    width: 160px;
                    left: -75px;
                    aspect-ratio: 1.4/2;
                    background-size: cover;
                    top: 50%;
                    transform: translateY(-50%) rotate(-5deg);
                    z-index: 1;
                    @include transition;
                    
                    @include smartphone{
                        width: 100px;
                        left: -5px;
                        top: 0px;
                    }
                }
                p{
                    font-family: 'Open sans' , arial;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    font-weight: $bold;
                    color: $primaryColor;
                    @include smartphone{
                        font-size: 16px;
                        margin-left: 90px;
                    }
                }
                .btn{
                    margin-left: 0;
                    @include smartphone{
                        margin: auto;
                    }
                }
                
            }
            
        }
    }

    .mikado{
        background-color: $blueWhiteColor;
        padding: 10px 0px;

        .container-mikado{
            @include container;
            display: flex;
            justify-content: space-between;
            @include tablet{
                flex-wrap: wrap;
                @include smartphone{
                    flex-direction: column;
                }
            }
            .logo-mikado{
                display: block;
                padding-bottom: 20px;
                width: 83px;
                background-repeat: no-repeat;
                @include tablet{
                    margin-bottom: 10px;
                }
            }
    
            ul{
                display: flex;
                @include smartphone{
                    flex-direction: column;
                }
                li{
                    margin-right: 100px;
                    
                    a{
                        font-size: 14px;
                        color: $primaryColor;
                        white-space: nowrap;
                    }
    
                    &:last-child{
                        margin-right: initial;
                    }
                }
            }
        }
    }
}