
.content{
    background-color: white;
    margin: 0px 20px;
    width: 100%;
    height: 100%;
    max-height: 500px;

    .content-img{
        overflow: hidden;
        .img{
            width: 100%;
            //aspect-ratio: 1.5/1;
            background-size: cover;
            //@include transition;
            vertical-align: middle;
            height: 100%;
            
            h2{
                color: white;
                background-color: rgba(0, 99, 163,0.7);
              //   background-color: rgba(192, 21, 101, 0.7);
                font-size: large;
                height: 60px;
                text-align: bottom;
                line-height: 9vh;
                opacity: 0.7;
               
            }
        }
    }

    .content-text{
        //padding: 7vh 1vw;
        text-align: left;
        padding-left: 3vw;
        
        position:relative;
        height:25%;
        ul{
            margin-top: 4VH;
            list-style-type: disc;
            height:100%;
            li{
            width:100%;
            }
        }

        @include desktop-xl{
            padding: 20px 20px 40px 20px;
        }

        h3{
            color: $primaryColor;
        }

    }

}
