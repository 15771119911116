/* ----------------------------------------------------------------
	Mixins
-----------------------------------------------------------------*/

//-------------------- Media Query --------------------//
@mixin smartphone ($smartphone: 640){
    @media only screen and (max-width: ($smartphone * 1px)){
        @content;
    }
}

@mixin tablet ($tablet: 768){
    @media only screen and (max-width: ($tablet * 1px)){
        @content;
    }
}

@mixin large ($large: 992){
    @media only screen and (max-width: ($large * 1px)){
        @content;
    }
}

@mixin desktop ($desktop: 1200){
    @media only screen and (max-width: ($desktop * 1px)){
        @content;
    }
}

@mixin desktop-xl ($desktop-xl: 1441){
    @media only screen and (max-width: ($desktop-xl * 1px)){
        @content;
    }
}

@mixin desktop-xxl ($desktop-xxl: 1680){
    @media only screen and (max-width: ($desktop-xxl * 1px)){
        @content;
    }
}

@mixin desktop-xxxl ($desktop-xxxl: 1920){
    @media only screen and (max-width: ($desktop-xxl * 1px)){
        @content;
    }
}

@mixin view-container ($desktop-xxxl: 1640){
    @media only screen and (max-width: ($desktop-xxxl * 1px)){
        @content;
    }
}
//-------------------- Background --------------------//
@mixin cover {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

//-------------------- Container --------------------//

@mixin container{
    max-width: 90vw;
    margin: auto;

    @media (min-width: $container){
        max-width: 1640px;
        padding: 0px 2vw;
    }

    @include desktop-xxl(){
        padding: initial;
    }
}

//-------------------- absolute --------------------//

@mixin absolute{
    content: '';
    position: absolute;
}

//-------------------- absolute --------------------//

@mixin transition{
    transition: .2s ease-in-out;
}
