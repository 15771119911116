.btn{
    font-family: 'Open Sans', arial;
    color: white;
    background-color: $primaryColor;
    width: fit-content;
    border: 1px solid transparent;
    font-size: 1em;
    padding: 15px 30px;
    margin: 0px 10px;
    transition: 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: normal;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    @include large{
        padding: 10px 20px;
        font-size: 0.8em;
    }

    
    
    &:hover{
        background-color: #fff;
        border-color: $primaryColor;
        color: $primaryColor;
    }
    
}

.btn-white{
    color: $primaryColor;
    border: 1px solid $primaryColor !important;
    background-color: #fff;
    &:hover{
        background: $primaryColor;
        color: #fff !important;
        border: 1px solid #fff !important;
    }
}

.btn-slick{
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 1px solid $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
}

.burger{
    display: none;
    position: relative;
    z-index: 9999;
    @include transition;
    @include large{
        display: flex;
        height: 100%;
        aspect-ratio: 1/1;
        background-color: $blueWhiteColor;
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    :nth-child(1){
        transform: translateY(-10px);
    }
    :nth-child(3){
        transform: translateY(10px);

    }
    span{
        display: block;
        content: "";
        height: 1px;
        width: 35%;
        background-color: $primaryColor;
        @include transition;
    }

}
.open{
    background-color: #fff;
    :nth-child(1){
        transform: translate(1px,2px) rotate(45deg);
    }
    :nth-child(2){
        transform: translateX(100px);
        opacity: 0;
    }
    :nth-child(3){
        transform: translate(0px,0px) rotate(-45deg);
    }
}