.jobs{
    @include container;
    .container-jobs{
        margin-right: -50px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 100px;
        @include tablet{
            justify-content: center !important;
        }
        .jobs-card{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 320px;
            margin-top: 50px;
            margin-right: 50px;
            @include tablet{
                margin: 50px 10px 0px 10px;
                width: 270px;
            }
            .img{
                position: relative;
                width: 100%;
                aspect-ratio: 1/1;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                border-color:#DBF0FA;
            }
            .content-job{
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                width: calc(100% - 8px);
                text-align: center;
                padding: 20px;
                &:before{
                    z-index: -1;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0px;
                    background-color: #dbf0fa;
                    //padding-top: 350px;
                    border-radius: 0px 0px 25px 25px;
                }
                h2{
                    max-width: 60%;
                    padding-bottom: 10px;
                }
                strong{
                    font-size: clamp(20px, 5vw, 24px);
                    width: calc(100% + 40px);
                    background-color: #fff;
                    padding: 5px 0;
                }
                a{
                    padding-top: 10px;
                    text-decoration: underline !important;
                    color: $primaryColor;
                    font-size: clamp(14px, 5vw, 16px);
                    font-weight: bold;
                }
            }
            
        }
    }
}