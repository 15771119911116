.next-btn-career,
.prev-btn-career{
    @include cover;
    @include transition;
    cursor: pointer;
    background-color: $primaryColor;
    position: relative;

    &:hover{
        background-color: $primaryColor;
    }
}


.slick-dots{
    bottom: 25px;
    
    li{
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #fff;
        margin: 0 10px;
        button{
            &:before{
                top: -4px;
                left: -4px;
                border: 1px solid #fff;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                content: "";
                opacity: 0;
                @include transition;
            }
        }
    }
}


.next-btn-career{
    transform: rotate(-90deg);
}

.prev-btn-career{
    transform: rotate(90deg);
}

.slick-disabled{
    opacity: 0.5;
    pointer-events: none;

    &:hover{
        background-color: transparent;
    }
}
.container-tendances .slick-slider .slick-list, .slick-slider .slick-track{
    display: flex;
    justify-content: center;
}
.slider-nav{
    position: absolute;
}

.arrow{
    width: 40px;
    aspect-ratio: 1/1;
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    cursor: pointer !important; 

    &:hover{
        background-color: $primaryColor;

        line,path{
            stroke: #fff;
        }
    }
    
    &-prev{
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);  
        
        &:hover{
            padding: 10px 10px 10px 6px;
        }
    }

    &-next{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);   

        &:hover{
            padding: 10px 6px 10px 10px;
        }
    }
}

.arrow-prev-type{
    left: 65px;

    @include desktop-xxl{
        left: 20px;
    }
}
.arrow-next-type{
    right: 65px;

    @include desktop-xxl{
        right: 20px;
    }
}

.arrow-prev-tendances{
    left: 75px;
    @include large{
        left: 50px;
        top: 38%;
    }
}
.arrow-next-tendances{
    right: 75px;
    @include large{
        right: 50px;
        top: 38%;
    }
}

.container-nav-offres{
    @include smartphone{
        display: none;
    }
}
.container-nav-offres-mobile{
    display: none;

    @include smartphone{
        display: flex;
        justify-content: space-between;
        width: auto;
        margin-top: 50px;
        
        .arrow-prev, .arrow-next{
            position: relative;

            &:hover{
                box-shadow: 0px 0px 0px 1px rgb(255, 255, 255);
            }
        }
    }
}
.container-slider-offres{
    position: relative;

    .arrow-prev-offres{
        left: 25px;
    }
    .arrow-next-offres{
        right: 15px;
    }
}

.prev-review, .next-review, .prev, .next{
    position: relative;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: $primaryColor;
        padding: 10px 6px 10px 10px;
        line,path{
            stroke: #fff;
        }
    }
}

.prev-review, .prev{
    transform: rotate(180deg);

}


.arrow-prev-folder, .arrow-next-folder{
    display: none;
    @include desktop{
        display: flex;
    }
}
.arrow-prev-folder{
    left: 30px;
}
.arrow-next-folder{
    right: 30px;
}

.slick-slide .inner-block,
.slick-slide[aria-hidden=true]:not(.slick-cloned) ~ .slick-cloned[aria-hidden=true] .inner-block {
    height: 100px;
    background-color: green;
    transform: translate(0px, 50px);
    transition: all 500ms ease;
}
.slick-center .inner-block,
.slick-slide[aria-hidden=true]:not([tabindex="-1"]) + .slick-cloned[aria-hidden=true] .inner-block {
    transform: translate(0px, 0px);
}

.slider-carte{
    .slick-track{
        display: unset;
    }
    .slick-list.draggable{
        height: auto !important;
    }

}
.arrow-next-agence, .arrow-prev-agence{
    transform: translateY(-50%) rotate(90deg) !important;  
    &:hover{
        border: 1px solid #fff;
    }
}
.arrow-next-agence{
    top: 60%;
}
.arrow-prev-agence{
    top: 40%;
    left: unset;
    right: 20px;
}