
.bon-cadeau{
    @include container;
    margin-bottom: 30px;
    p{
        color: $primaryColor;
    }
    ul{
        display: flex;
        border-bottom: 2px solid $primaryColor;
        margin-top: 60px;
        @include smartphone{
            justify-content: space-between;
        }
        li{
            margin-right: 40px;
            @include smartphone{
                margin-right: 0px;        
            }
            a{
                padding: 20px;
                display: block;
                font-weight: $bold;
                color: $primaryColor;
                @include transition;
                @include smartphone{
                    text-align: center;
                    padding: 15px;
                }
            }
            .active{
                background-color: $primaryColor;
                color: #fff;

            }
        }
    }
    .container-bon{

        h2{
            margin: 60px 0 30px 0;
        }
        h3{
            color: $primaryColor;
            text-align: center;
            margin-top: 90px;
            margin-bottom: 10px;
            font-size: clamp(20px, 5vw, 26px);
            @include large{
                margin-top: 50px;
            }
        }
        .content-bon{
            display: flex;
            margin-bottom: 80px;
            align-items: center;
            @include large{
                flex-direction: column;
            }

            .cartes{
                position: relative;
                width: 50%;
                height: 400px;
                margin-top: 50px;
                margin-left: 7%;
                @include large{
                    margin: auto;
                    margin-top: 40px;
                    width: 500px;
                    @include smartphone{
                        width: 400px;
                        height: 230px;
                    }
                }
                max-height: 340px;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    filter: drop-shadow(5px 5px 10px #c4c4c4);
                    @include transition;
                    &:hover{
                        transform: translateY(-20px);
                    }
                    @include smartphone{
                        width: 90vw;
                    }
                }
            }
            .container-montant{
                margin-top: 40px;
                margin-left: 40px;
                @include large{
                    margin-left: 0;
                }
                .montant{
                    display: flex;
                    margin-bottom: 20px;
                    button{
                        display: block;
                        font-weight: $bold;
                        &:first-child{
                            margin-left: 0;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                .total{
                    .container-number{
                        .content{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-top: 1px solid $primaryColor;

                            &:last-child{
                                padding: 15px 0;
                                border-bottom: 1px solid $primaryColor;
                            }
                            .number{
                                margin: 10px 0;
                                align-items: center;
                                input{
                                    padding: 15px;
                                }
                                label{
                                    margin-left: 20px;
                                    font-weight: $bold;
                                }
                            }
                        }
                        a{
                            svg{
                                width: 1em;
                                height: 1em;
                                fill: $primaryColor;
                            }
                        }
                        p{
                            color: #000;
                        }
                    }
                    .btn{
                        margin-left: 0;
                        margin-top: 40px;
                    }
                }
            }
        }
    }
    .container-cadeau{
        h2{
            margin: 60px 0 30px;
        }
        p{
            padding: 1em 0;
        }
        .content-cadeau{
            margin-top: 180px;
            display: flex;
            justify-content: space-around;
            margin-bottom: 80px;
            position: relative;

            @include large{
                flex-direction: column;
            }

            &:before{
                content: '';
                display: block;
                width: 100vw;
                height: 70%;
                background-color: $blueWhiteColor;
                position: absolute;
                top: -130px;
                
            }
            
            input[type=text],input[type=tel]{
                width: 100%;
                padding: 12px 10px;
                margin: 8px 0;
                box-sizing: border-box;
                border: 1px solid $primaryColor;
                @include transition;
                &:focus{
                    box-shadow: 0px 0px 0px 1px $primaryColor;
                }
                
            }
            .liste{
                @include large{
                    margin-top: 100px;
                }
            }
            .rechercher, .liste{
                background-color: $primaryColor;
                text-align: center;
                color: #fff;
                padding:50px 80px;
                padding-top: 120px;
                width: 38%;
                position: relative;

                @include desktop{
                    width: 45%;
                    padding: 100px 40px 25px 40px;

                    @include large{
                        width: 100%;
                    }
                }

                .icon{
                    background-color: #fff;
                    padding: 40px;
                    border-radius: 50%;
                    width: fit-content;
                    position: absolute;
                    top: -80px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                h3{
                    font-size: clamp(20px, 5vw, 26px);
                }
                p{
                    color: #fff;
                    margin: 30px 0;
                    @include large{
                        margin: 15px 0;
                    }
                }
                .column{
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    label{
                        font-weight: $bold;
                    }
                }
                .btn{
                    margin-top: 30px;
                }
            }
        }
    }
}


