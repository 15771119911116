.container-single-carte{
    @include container;

    .content{
        display: flex;
        margin-top: 60px;
        @include desktop-xl{
            align-items: center;
            @include smartphone{
                flex-direction: column;
            }
        }
        .container-img{
            display: flex;
            width: 50%;
            @include desktop-xl{
                flex-direction: column;
                @include smartphone{
                    flex-direction: row;
                }
                @include smartphone{
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
            .img{
                display: block;
                width: 50%;
                aspect-ratio: 1/1;
                margin-right: 20px;
                background-size: cover;
                background-position: center;
                @include desktop-xl{
                    width: 100%;
                    &:nth-child(2){
                        display: none;
                        @include large{
                            display: block;
                            margin-top: 10px;
                        }
                    }
                }
                @include smartphone{
                    width: 50%;
                    &:nth-child(2){
                        margin-top: 0;
                    }
                }
            }
        }
        .container-info{
            margin-left: 100px;
            @include large{
                margin-left: 50px;
                @include smartphone{
                    width: 100%;
                    margin-left: 0;
                }
            }
            .content-info{
                display: flex;
                height: 85%;
                
                .info{
                    margin-top: 40px;
                    .adresseHoraire{
                        display: flex;
                        margin-bottom: 10px;
                        @include large{
                            flex-direction: column;
                            @include smartphone{
                                flex-direction: row;
                            }
                        }
                        .adresse,.horaire{
                            span{
                                color: $primaryColor;
                                font-weight: $bold;
                                font-size: clamp(16px, 3vw, 18px);
                            }
                        }
                        .horaire{
                            margin-left: 100px;
                            @include large{
                                margin: 20px 0;
                                @include smartphone{
                                    margin: 0 0 0 50px;
                                }
                            }
                        }
                    }
                }
                .contact{
                    display: flex;
                    flex-direction: column;
                    a{
                        font-size: clamp(16px, 3vw, 18px);
                        color: #000;
                        margin: 5px 0;
                    }
                }
            }
        }
        .btn{
            display: block;
            margin-left: 0;
            margin-top: 40px;
        }
    }
}

.team-agence{
    @include container;
    margin: 60px auto;
    .container-team{
        ul{
            display: flex;
            justify-content: space-between;
            li{
                margin: 0px 23px;
                margin-top: 60px;
                background-color: $blueWhiteColor;
                border-top-left-radius: 130px;
                border-top-right-radius: 130px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                width: 260px;
                text-align: center;
                .photo{
                    width: 260px;
                    aspect-ratio: 1/1;
                    display: block;
                    border-radius: 50%;
                    background-size: cover;
                }
                h3{
                    margin-top: 20px;
                    color: $primaryColor;
                }
                p{
                    font-weight: $bold;
                }
                .langues{
                    display: flex;
                    justify-content: center;

                    .img{
                        display: block;
                        width: 28px;
                        height: 18px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin: 20px 5px;

                    }
                }
            }
        }
        .btn{
            display: block;
            margin: auto;
            margin-top: 60px;

        }
    }
}

.form-agences{
    margin-bottom: 100px;

    h2{
        border-bottom: 0px !important;
    }
    textarea{
        width: 100% !important;
    }
    
}

.form-agences-contact form .column-textarea, .form-folder form .column-textarea {
    max-width: 1400px;
    width: unset;
    textarea{
        font-family: Arial;
    }
}
.form-agences-contact{
    .wrap{
        align-items: center;
    }
    select{
        position: relative;
        background-color: #fff;
        border: 1px solid $primaryColor;
        color: #000 !important;
        padding: 10px;
        font-size: 18px;
        font-family: 'Work Sans', arial;
        font-weight: 500;
        margin: 0;      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image:
        linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        linear-gradient(to right, $primaryColor, $primaryColor);
        background-position:
            calc(100% - 16px) calc(1em + 2px),
            calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size:
            5px 5px,
            5px 5px,
            2em 2.5em;
        background-repeat: no-repeat;
    }
    p{
        margin-top: 8px;
        width: 400px;
        font-size: 12px;
    }
}