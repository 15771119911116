
header{
    width: 100vw;
    height: fit-content;
    box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.13);
    position: fixed;
    background-color: #fff;
    z-index:9999;

    .container-header{
        position: relative;
        @include container;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        @include large{
            position: unset;
            padding-bottom: 50px;
            max-width: 100vh;
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
        }
        .logo-lang{
            display: block;
            position: relative;
            z-index: 9999;
            .logo{
                @include cover;
                display: block;
                height: 0;
                padding-bottom: 6%;
                width: 25%;
                margin: auto;

                @include large{
                    margin-top: 10px;
                    width: 50%;
                    @include smartphone{
                        width: 70%;
                        margin-left: 0;
                    }
                }
                
            }
    
            .lang{
                display: flex;
                position: absolute;
                top: 50%;
                left: 70px;
                transform: $transform;
                @include large{
                    position: absolute;
                    left: 80px;
                    top: 100%;
                    @include smartphone{
                        transform: translate(0);
                        left: 15px;
                        bottom: -30px;
                        top: unset;
                    }
                }
                a{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    font-size: 15px;
                    color: $darkColor;
                    margin: 0px 15px;
                    text-transform: uppercase;
                    @include transition;
                    @include smartphone{
                        font-size: 10px;
                        margin: 0 10px;
                    }
                    &:before{
                        content: "";
                        display: block;
                        height: 0px;
                        width: 0px;
                        border-radius: 50%;
                        background-color: $primaryColor;
                        position: absolute;
                        @include transition;
                        z-index: -1;

                    }
                    &:after{
                        @include absolute;
                        height: 33px;
                        width: 33px;
                        top: 50%;
                        left: 50%;
                        transform: $transform;
                        border-radius: 50%;
                        border: 1.5px solid $primaryColor;
                        z-index: -1;

                        @include smartphone{
                            height: 25px;
                            width: 25px;
                        }
                    }
                    &:hover{
                        &:before{
                            width: 33px;
                            height: 33px;
                            @include smartphone{
                                height: 25px;
                                width: 25px;
                            }
                        }
                        color: #fff;
                    }
                }
    
                .active{
                    color: $whiteColor;
    
                    &:after{
                        background-color: $primaryColor;
                    }
                }
            }
            .search{
                position: absolute;
                top: 50%;
                right: -90px;
                transform: $transform;
                display: flex;

                @include large{
                    position: absolute;
                    bottom: -45px;
                    top: unset;
                    right: unset;
                    left: 50%;

                    @include smartphone{
                        display: none;
                    }
                }

                input{
                    border-radius: 20px;
                    border: 1.5px solid $primaryColor;
                    padding: 3px 10px;
                    color: $primaryColor;
                    @include transition;

                    &:hover{
                        box-shadow: 0px 0px 0px 1px rgba(0,99,163,1);
                    }

                    &:focus{
                        box-shadow: 0px 0px 0px 1px rgba(0,99,163,1);
                    }

                    &::placeholder{
                        color: #000;
                    }
                }
                
                .icon-search{
                    width: 20px;
                    aspect-ratio: 1/1;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                    background-size: contain;
                    background-repeat: no-repeat;
                    @include transition;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
        }
        
        .social{
            position: absolute;
            background-color: $primaryColor;
            top: 220px;
            right: calc(2vw - 50px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 170px;
            width: 50px;
            
            @include view-container{
                right: -50px;

                @include large{
                    right: 0px;

                    @include smartphone{
                        top: 120px;
                    }
                }
            }

            a{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin: 5px;

                &:before{
                    content: "";
                    display: block;
                    height: 0px;
                    width: 0px;
                    border-radius: 50%;
                    background-color: #fff;
                    position: absolute;
                    @include transition;
                    z-index: 9;

                }
                &:after{
                    @include absolute;
                    height: 33px;
                    width: 33px;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    z-index: 1;
                }
                
                svg{
                    z-index: 9;
                    
                }
                &:hover{
                    path{
                        fill: $primaryColor;
                    }
                    &:before{
                        width: 33px;
                        height: 33px;
                    }
                }
            }
        }

        .agences{
            position: absolute;
            right: calc(2vw - 50px);
            top: 680px;
            transform: rotate(-90deg);
            transform-origin: 16px -104px;
            width: 170px;
            height: 50px;
            background-color: $primaryColor;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            @include view-container{
                right: -50px;

                @include large{
                    right: 0px;

                    @include smartphone{
                        top: 620px;
                    }
                }
            }

            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #fff;
                text-transform: uppercase;
                width: fit-content;
                svg{
                    margin-right: 15px;
                }
            }
        }

        nav{
            position: relative;
            @include large{
                position: unset;
            }
            
            .show-nav{
                right: 0px;
                
            }
            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translateY(-$menu);
                
                @include large{
                    transform: unset;
                    right: -100vw;
                    width: 100vw;
                    height: 100vh;
                    position: absolute;
                    top: 0px;
                    flex-direction: column;
                    background-color: $blueWhiteColor;
                    padding: 20px;
                    box-shadow: -13px 13px 16px 0px rgba(0,0,0,0.13);
                    transition: 0.5s ease-in-out;
                    z-index: -1;
                    padding-top: 100px;
                }

                li{
                    white-space: nowrap;
                    position: relative;
                    
                    &:hover{

                        .sub-menu{
                            bottom: auto;
                            opacity: 1;
                            pointer-events: all;
                            
                            @include large{
                                position: inherit;
                                width: fit-content;
                                right: inherit;
                                height: fit-content;
                            }
                        }
                        a{
                            position: relative;
                            @include large{
                                text-align: center;
                            }
                            &:after{
                                width: 80% !important;
                            }
                        }
                        
                    }
                    &:after{
                        @include absolute;
                        height: 40%;
                        width: 1.5px;
                        background-color: $primaryColor;
                        top: 55%;
                        transform: $transform;
                        right: 0;
                        @include large{
                            width: 0px;
                            height: 0;
                        }
                        
                    }
                 
                    &:nth-last-child(1){

                        &:after{
                            width: 0px;
                            @include large{
                                width: 1.5px;

                            }
                        }
                    }
                    input{
                        display:none;
                        @include smartphone{
                            display: inline-block;
                            border-radius: 20px;
                            border: 1.5px solid $primaryColor;
                            padding: 3px 10px;
                            color: $primaryColor;
                            @include transition;
                            &:focus{
                                box-shadow: 0px 0px 0px 1px rgba(0,99,163,1); 
                            }
                        }
                    }
                    .icon-search{
                        display:none;
                        @include smartphone{
                            display: inline-block;
                            width: 20px;
                            aspect-ratio: 1/1;
                            margin-left: 0.5rem;
                            cursor: pointer;
                            border: none;
                            background-color: transparent;
                            background-size: contain;
                            background-repeat: no-repeat;
                            @include transition;
                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                    a{
                        display: block;
                        font-size: clamp(14px, 1.3vw, 18px);
                        color: $darkColor;
                        font-weight: $regular;
                        padding: 10px 30px;
                        text-transform: uppercase;

                        @include large{
                            font-size: 18px;
                            padding: 15px 0px;
                        }

                        &:hover{
                            color:$primaryColor;
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 2px;
                            width: 0%;
                            background-color: $primaryColor;
                            z-index: 2;
                            @include transition;


                        }
                    }
    
                    .active{
                        color: $primaryColor;
                        font-weight: $bold;
                    }
                    
                }
                
            }
        }
    }
}

.letzshop{
    position: absolute;
    width: 90px;
    aspect-ratio: 1/1;
    right: 2vw;
    top: 83vh;
    @include transition;

    &:hover{
        transform: scale(1.1);
    }
}

//liste déroulante

.sub-menu{
    position: absolute;
    margin-top: $menu;
    background-color: #fff;
    flex-direction: column;
    width: -webkit-fill-available;
    opacity: 0;
    z-index: -9;
    padding-top: $menu;
    padding-bottom: 0 !important;
    display: flex;
    pointer-events: none;
    @include transition;
    
    @include large{
        box-shadow: none !important;
        top: 0px !important;
        padding-bottom: 10px !important;
        padding-top: 0 !important;
        z-index: 999 !important;
    }
    &:hover{
            bottom: auto;
            opacity: 1;

            @include large{
                position: inherit;
                width: fit-content;
                right: inherit;
            
        }
        a{
            position: relative;
            
            &:after{
                width: 80% !important;
            }
        }
        
    }
    li{
        text-align: center;
        padding: 10px !important;
        white-space: pre-wrap !important;
        width: 100%;

        @include large{
            text-align: right;
            padding: 5px !important;

            &:first-child{
                justify-content: flex-end;
                margin: 0 !important;
            }
        }
        
        &:after{
            width: 0px !important;
            
        }
        &:hover{
            background-color: #F3F3F3;
            @include large{
                background-color: transparent;
            }
        }
        a{
            text-transform: capitalize !important;
            font-weight: $light !important;
            padding: 0 10px !important;

            @include large{
                padding: 0 5px !important;
                font-size: 16px !important;
                
            }
            &:after{
                height: 0 !important;
                margin: 0 !important;
            }
        }
    }
}

//Cache les réseaux en mobile

.social, .agences{
    @include transition;
}
.hide{
    @include large{
        transform: translateX(45px) !important;
    }
}

.hide-agences{
    @include large{
        transform: translateX(45px) rotate(-90deg) !important;
    }
}