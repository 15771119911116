.info-voyage{
    @include container;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    @include smartphone{
        flex-direction: column;
    }
    .slider{
        width: 68%;
        display: flex;

        @include desktop-xxl{
            width: 60%;
            @include desktop{
                width: 50%;
                flex-direction: column;
                @include smartphone{
                    width: 100%;
                }
            }
            
        }

        .img{
            background-size: cover;
            background-position: center;
            width: 50%;
            aspect-ratio: 1/1;
            margin-right: 10px;

            @include desktop{
                width: 100%;
                margin-right: 0;
                margin-top: 10px;
                height: -webkit-fill-available;
                aspect-ratio: unset;

                @include smartphone{
                    aspect-ratio: 1.2/1;
                }

                &:last-child{

                    display: none;
                }
            }
        }
    }
    .content{
        width: 30%;
        padding:0 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

            @include desktop-xxl{
                width: 38%;

                @include desktop-xl{
                    width: 41%;

                    @include desktop{
                        width: 49%;
                        justify-content: center;

                        @include smartphone{
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
            }
        h1{
            color: $primaryColor;
        }
        h1,h2{
            margin: 20px 0;
        }
        ul{
            li{
                font-weight: 600;
                margin: 10px 0;

                span{
                    color: $primaryColor;
                    font-size: 1rem;
                }
            }
        }
    }
    .content-icon{
        display: flex;
        align-items: center;
        margin-top: 20px;
        .icon{
            background-size: cover;
            width: 60px;
            aspect-ratio: 1/1;
            z-index: 1;
        }
        p{
            background-color: $blueWhiteColor; 
            color: $primaryColor;   
            padding: 10px;
            padding-left: 30px;
            margin-left: -20px;
        }
    }
    .cta{
        display: flex;
        margin-top: 20px;
        .btn{
            display: block;
            margin-left: 0;
        }
    }
}

.prestations{
    @include container;
    ul{
        list-style-type: disc;
        margin-top: 20px;
        li{
            margin-left: 15px;
            padding: 5px 0;
            &::marker{
                color: $primaryColor;
            }
        }
       
    }
}

.deroulement{
    position: relative;
    h2{
        @include container;
        margin-top: 80px;
        margin-bottom: 20px;
    }
    .container-deroulement{
        @include container;
        overflow-y: scroll;
        height: 75vh;
        padding: 0 12px !important;
        
        .content{
            width: 70%;
            border-left: 2px dashed $primaryColor;
            padding-left: 30px;
            margin-top: 40px;

            @include tablet{
                width: 90%;
            }
            .content-text{
                margin-top: 30px;
                h3{
                    color: $primaryColor;
                    position: relative;
                    &::before{
                        content: url(../images/noun-location.svg);
                        position: absolute;
                        left: -43px;
                        height: 20px;
                        width: 20px;
                        display: block;
                    }
                }
                &:last-child{
                    padding-bottom: 15%;
                }
            }    
        }
    }
    &::after{
        content: '';
        display: block;
        position: absolute;
        width: 100vw;
        height: 30%;
        bottom: 0;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,0.7525603991596639) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }      
}