.home{
    .container-intro{
        h2{
            color: #000 !important;
        }
    }
}

.container-tendances{
    position: relative;
    background-color: $blueWhiteColor;
    text-align: left;
    padding: 60px;
    @include large{
        padding: 40px 20px;
    }
    h2{
        margin-bottom: 50px;
        color: white;
    }
    span{
        font-size: clamp(16px,3vw,18px);
        font-style: italic;
    }
    .content-tendances{
        display: flex;
        @include container;

        .content{
            background-color: white;
            margin: 0px 20px;
            width: 30%;
            @include large{
                margin-right: 0;
                
                @include smartphone{
                    margin: 0;
                }
            }

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }

            .content-img{
                overflow: hidden;
                .img{
                    width: 100%;
                    aspect-ratio: 1.5/1;
                    background-size: cover;
                    @include transition;

                }
            }

            &:hover{
                .content-img{
                    .img{
                        transform: scale(1.1);
                    }
                }
            }

            .content-text{
                padding: 30px 40px 40px 40px;

                @include desktop-xl{
                    padding: 20px 20px 40px 20px;
                }

                h3{
                    color: $primaryColor;
                    min-height: 50px;
                }
                p{
                    margin: 20px 0;
                    margin-bottom: 40px;
                }
            }
        }
    }
}

.container-offres{
    position: relative;
    color: white;
    height: fit-content;
    padding: 80px 0;

    @include tablet{
        padding-top: 40px;
    }

    h2{
       color: $primaryColor;
       margin-bottom: 70px;
       text-align: center;
    }

    .carre{
        position: absolute;
        background-color: $primaryColor;
        bottom: 45px;
        height: 77%;
        width: 45%;
        z-index: -1;
        @include desktop-xxl{
            height: 72%;
        }
        @include desktop{
            width: 50%;
            height: 75%;
        }

        @include large{
            width: 60%;

            @include tablet{
                width: 70vw;
                height: 74%;
                bottom: 60px;
                
                @include smartphone{
                    width: 80vw;
                    height: 80%;
                }
            }
        }
    }
    .container-slider-offres{
        width: 73%;

        @include desktop-xxl{
            width: 65%;

            @include desktop{
                width:63%;

                @include large{
                    width:42%;
                }
            }
        }
    }
    .slider-offres{
        @include container;
        position: relative;
        height: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .content-offres{
            @include container;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: -10px;

            .content-text{
                width: fit-content;
                display: flex;
                flex-direction: column;
                max-width: 400px; 

                @include desktop{
                    max-width: 350px;
                }             

                @include smartphone{
                    width: 50vw !important;
                }

                p{
                    margin: 40px 0;
                    max-width: 350px;
                    
                }
                
                h2{
                    text-align: left !important;
                    color: white;
                    margin-bottom: 20px !important;
                }

                .btn{
                    margin: 0px;
                    margin-top: 20px ;
                    border: 1px solid white;
                    display: block;
                }

                .slick-slide{
                    height: fit-content;
                    opacity: 0;
                    @include transition;
                    transition-delay: 300ms;
                }
                .slick-current{
                    opacity: 1;
                }
            }
            
        }

        .content-img{
            display: flex;
                
                @include smartphone{
                    padding-bottom: 40px;
                    z-index: -1;
                }

            .img{
                width: 30%;
                aspect-ratio: 1/1.55;
                margin: 10px;
                background-size: cover;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                overflow: hidden;

                @include smartphone{
                    aspect-ratio: 1/2.2;
                    background-position: center;
                    margin-bottom: 138px;
                }

                .content-info{
                    transition: 0.5s ease-in-out;
                    z-index: 2;
                    text-align: center;

                    h2{
                        color: #fff;
                        margin-bottom: 10px;
                        font-weight: 300 !important;
                        font-size: clamp(20px, 1.6vw, 26px);
                    }

                    h3{
                        text-transform: capitalize;
                        font-weight: 300 !important;
                        font-size: clamp(20px, 1.6vw, 26px);
                    }

                }
            }

            .slick-slide{
                &:before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    background: rgb(0,0,0);
                    background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);                    transition: 0.5s ease-in-out;
                    z-index: 1; 
                }

                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    background-color: $primaryColor;
                    opacity: 0.6;
                    transition: 0.5s ease-in-out;
                }
            }

            .slick-current{

                &:after{
                    height: 0%;
                    opacity: 0;
                }
                &:before{
                    height: 0%;
                    opacity: 0;
                }

                .content-info{
                    transform: translateY(50vh);
                    opacity: 0;
                }
            }
        }
    }
}

.container-sur-mesure{
    @include container;
    margin-top: 20px;

    .content{
        width: 100%;
        aspect-ratio: 2/1;
        position: relative;
        background-size: cover;
        background-position: center;

        @include smartphone{
            aspect-ratio: 1/1;
        }

        .logo-sur-mesure{
            background-color: #D25343;
            width: 8%;
            aspect-ratio: 1/1;
            position: absolute;
            top: 0;
            left: 50%;
            background-size: 80%;
            background-position: center;
            background-repeat: no-repeat;
            transform: translateX(-50%);
        }
        
        .content-text{
            text-align: center;
            background-color: #D25343;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px 0px 40px 0;

            @include large{
                padding: 10px;
                padding-bottom: 25px;
            }

            .btn{
                background-color: #D25343;
                border:1px solid white !important;

                &:hover{
                    background-color: white;
                    border: #D25343;
                    color: #D25343;
                }
            }

            h2{
                color: #fff;
                margin-bottom: 40px;

                @include large{
                    margin-bottom: 20px;
                    font-size: 18px;
                }

            }
        }
    }
}


.container-folder{
    text-align: center;
    background-color: $blueWhiteColor;
    padding: 70px;
    padding-top: 50px;

    @include smartphone{
        padding: 40px;
    }
    h2{
        color: $primaryColor;
    }

    .container-slider-folder{
        position: relative;
    }

    .slider-folder{
        max-width: 1200px;
        height: fit-content;
        display: flex;
        justify-content: center;
        margin: 40px auto;

        @include tablet{
            margin: 20px auto 30px auto;
        }

        .item{
            width: 256px;
            aspect-ratio: 1.4/2;
            background-size: cover;
            margin: 0.5em;
        }
    }
}

.container-guide{
    @include container;
    display: flex;
    margin: 80px auto 80px auto;
    text-align: center;
    @include smartphone{
        flex-direction: column;
    }

    .img{
        width: 65vw;
        aspect-ratio: 1.8/1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include large{
            aspect-ratio: 1.2/1;
            width: 50vw;

            @include smartphone{
                width: 100%;
                aspect-ratio: 1.8/1;
            }
        }
    }

    .content-text{
        background-color: #9ED4E0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 37vw;
        position: relative;
        padding: 30px;

        @include large{
            width: 50vw;

            @include smartphone{
                padding: 20px;
                width: 100%;
                aspect-ratio: 1.8/1;
            }
        }

        h2{
            font-size: clamp(20px, 2vw, 40px);
            font-family: 'Open-sans', arial;
            font-weight: 300;
            margin-top: 20px;
            margin-bottom: 10px;
            color: white !important;

            @include smartphone{
                margin-top: 15px;
            }
        }

        span{
            font-family: 'OoohBaby-Regular', arial;
            font-size: clamp(45px, 5vw, 90px);
            text-transform: lowercase;
            color: #fff;
            line-height: 0.7;
            margin-top: 0px;
            margin-bottom: 20px;

            @include smartphone{
                margin-bottom: 20px;
            }
        }

        .icon{
            width: 16%;
            aspect-ratio: 1/1;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;

            @include smartphone{
                width: 14%;
                aspect-ratio: 1.5/1;
            }

            &-tl{
                top: 20px;
                left: 20px;

                @include smartphone{
                    top: 10px;
                    left: 10px;
                }
            }

            &-t{
                top: 20px;

                @include smartphone{
                    top: 10px;
                }
            }

            &-tr{
                top: 20px;
                right: 20px;
                
                @include smartphone{
                    top: 10px;
                    right: 10px;
                }
            }

            &-bl{
                bottom: 20px;
                left: 20px;

                @include smartphone{
                    bottom: 10px;
                    left: 10px;
                }
            }

            &-br{
                bottom: 20px;
                right: 20px;

                @include smartphone{
                    bottom: 10px;
                    right: 10px;
                }
            }
        }
    }
    .btn{
        margin-top: 5px;
    }
    .btn:hover{
        background-color: #fff;
    }
}
.background-blur {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5); /* Noir semi-transparent */
    backdrop-filter: blur(4px);
    transform: scale(0);
    transition: .5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container-popup-voiture{
    .popup{
        padding: 0 !important;
    }
}
.popup{
    position: relative;
    transition: .5s ease;
    max-height: 70vh;
    width: 1000px;
    z-index: 1;
    border-radius:20px;
    overflow: scroll;
    background-color: #fff;
    padding: 20px;
    box-shadow: -1px 0px 20px 0px rgba(0,0,0,0.25);
    margin-top: 100px;
    @include desktop{
        width: 600px;  
        margin-top: 50px;   
    }
    @include smartphone{
        width: 350px; 
        margin-top: 25px;   
    }

    .close-popup {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        svg{
            width: 1.5em;
            height: 1.5em;
            fill: rgb(0, 99, 163);
        }
    }

    iframe{
        border-radius:10px;
    }

    form{
        @include tablet{
            height: 70vh;
            overflow: scroll;
        }
        p{
            width: 49%;  
            @include tablet{
                width: 100%;
            } 
        }
        .clear{
            display:flex;
            justify-content: space-between;
            @include tablet{
                flex-direction: column;
            }
        }
        .btn-small{
            margin: 0;
            margin-top: 10px;
            margin-bottom: 20px;
            padding: 5px 20px;
        }
        .container-width{
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .btn{
                display:block;
                margin: auto;
                margin-top: 20px;
            }
            label{
                display: flex;
                align-items: center;
                margin: 0 10px;
                @include tablet{
                    margin: 0;
                    margin-right: 20px;
                }
            }
            strong{
                margin: 5px 0;
                @include tablet{
                    width: 100%;

                }
            }
        }
        .passengers{
            width: 49%;
            @include tablet{
                width: 100%;
            } 
            p{
                width: 100%;
            }
        }
        select{
            position: relative;
            width: 100%;
            background-color: #fff;
            border: 1px solid $primaryColor;
            padding: 10px;
            font-size: 18px;
            font-family: 'Work Sans', arial;
            font-weight: 500;
            margin: 0;      
            margin-top: 5px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image:
            linear-gradient(45deg, transparent 50%, white 50%),
            linear-gradient(135deg, white 50%, transparent 50%),
            linear-gradient(to right, $primaryColor, $primaryColor);
            background-position:
                calc(100% - 16px) calc(1em + 2px),
                calc(100% - 11px) calc(1em + 2px),
                100% 0;
            background-size:
                5px 5px,
                5px 5px,
                2em 2.5em;
            background-repeat: no-repeat;
        }
    
        input[type=text],input[type=tel]{
            width: 100%;
            padding: 12px 10px;
            margin: 8px 0;
            font-size: 16px;
            box-sizing: border-box;
            border: 1px solid $primaryColor;
            @include transition;
            font-family: 'Work Sans',arial;
            &:focus{
                box-shadow: 0px 0px 0px 1px $primaryColor;
            }
        }
        input[type="radio"] {
            margin-right: 5px;
            appearance: none;
            position: relative;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            border-radius: 50%;
            border: 1px solid $primaryColor;
            transition: .2s ease-in-out; /* Transition appliquée ici */
        }
        
        input[type="radio"]::before {
            content: "";
            position: absolute;
            top: 6px;
            left: 6px;
            width: 0px; /* Initialiser à 0 pour la transition */
            height: 0px; /* Initialiser à 0 pour la transition */
            background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
            border-radius: 50%;
            transition: .2s ease-in-out; /* Transition aussi ici */
        }
        
        input[type="radio"]:checked::before {
            top: -1px;
            left: -1px;
            width: 16px;
            height: 16px;
        } 
        input[type="checkbox"] {
            appearance: none;
            position: relative;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            border-radius: 3px;
            border: 1px solid $primaryColor;
            transition: .2s ease-in-out; /* Transition appliquée ici */
            display: flex;
        }
        
        input[type="checkbox"]::before {
            content: "";
            position: absolute;
            top: 7px;
            left: 7px;
            width: 0px; /* Initialiser à 0 pour la transition */
            height: 0px; /* Initialiser à 0 pour la transition */
            background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
            border-radius: 2px;
            transition: .2s ease-in-out; /* Transition aussi ici */
        }
        
        input[type="checkbox"]:checked::before {
            top: 2px;
            left: 2px;
            width: 10px;
            height: 10px;
        }
    }
}