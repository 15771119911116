.container-intro{
    @include container;
    width: 100vw !important;

        @include large{
            max-width: 100vw !important;
        }

    .container-slider-intro{position: relative;}
    .container-guide{
        padding-left: 0;
        padding-right: 0;
        margin-top: 220px;
        @include large{ 
            margin-top: 130px;
            margin-bottom: 40px;
        }
    }
    .slider-intro{
        width:100%;
        display: block;
        margin: auto;
        margin-top: 220px;
        margin-bottom: 70px;
        overflow: hidden !important;
        position: relative;

        @include large{ 
            margin-left: 0;
            margin-right: 0;
            margin-top: 130px;
            margin-bottom: 40px;
        }
        
        .img{
            display: none;
            margin: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 100% !important;
            aspect-ratio: 3/1;
            &:first-child{
                display: block;
            }
            @include smartphone{
                background-position: 45%;
                background-image: cover;
                aspect-ratio: 1/1;
            }

            
        }
        .slick-track{
            .img{
                display: block;
            }
        }
    }

    h2{
        font-size: 1em;
        text-align: center;
        margin-bottom: 30px;
        font-weight: $light;
        @include smartphone{
            margin-bottom: 10px;
        }
    }

    p{
        text-align: center;
        font-size: 1em;
    }

    .cta{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 90px;

        @include smartphone{
            flex-direction: column;
            margin-top: 20px;
        }

        p{
            margin-right: 20px;
        }
        
        &-btn{
            display: flex;
            @include smartphone{
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 20px;
            }
            .btn{
                display: block;
                @include smartphone{
                    margin: 0 5px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}