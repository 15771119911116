 .container-type{
    @include container;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    min-width:350px;
    min-height: 350px;
    .slider-type{
        width: calc(100% + 8px);
        display: flex;
        justify-content: space-between;
        margin: auto;
        margin-bottom: 50px;

        .img{
            background-size: cover;
            aspect-ratio: 1.2/1.8;
            position: relative;
            cursor: pointer;
            margin: 5px;

            p{
                text-transform: uppercase;
                transition: 0.4s ease-in-out;
                color: white;
                font-size: clamp(18px, 3vw, 26px);
                font-weight: 300;
            }

            .content{
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: rgba(0, 99, 163, 0.6);
                width: 100%;
                height: 25%;
                transition: 0.4s ease-in-out;
            }
            &:hover{
                .content{
                    height: 100%;
                }
            }
        }
    }
}