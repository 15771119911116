.container-rgpd{
    @include container;
    margin-bottom: 80px;
    margin-top: 200px;
    @include large{
        margin-top: 125px;
    }
    h1,h2,h3,h4,h5{
        u{
            text-decoration: none;
        }
    }
    h3{
        color: $primaryColor;
        margin: 20px 0;
    }
    h4{
        color: $primaryColor;
        margin: 10px 0;
    }
    h1{
        font-size: clamp(28px, 5vw, 36px);
        color: $primaryColor;
        margin: 5px 0;
        margin-top: 40px;
        margin-bottom: 60px;
    }
    h5{
        font-size: clamp(20px, 5vw, 28px);
        color: $primaryColor;
        margin: 5px 0;
        margin-top: 40px;
        &:first-child{
            margin-top: 0px;
        }
    }
    br{
        display: none;
    }
    a{
        font-weight: 500;
        color: $primaryColor;
        text-decoration: underline !important;
        font-size: 18px;
        strong{
            text-decoration: underline !important;
            font-weight: 500;  
        }
    }
    p{
        margin-top: 10px;
        margin-bottom: 20px;
        width: 90%;
        @include large{
            width: 100%;
        }
    }
    ul{
        list-style-type: disc;
        list-style: inside;
        margin-bottom: 20px;
        width: 90%;
        li{
            font-weight: 500;
            margin: 5px 0;
        }
    }

    .nav-cgv{
        display: flex;
        border-bottom: 2px solid $primaryColor;
        margin-top: 60px;
        margin-bottom: 60px;
        list-style-type: none !important;
        list-style: none !important;
        width: 100%;
        @include smartphone{
            justify-content: space-between;
        }
        li{
            margin-right: 40px;
            margin-bottom: 0;

            @include smartphone{
                margin-right: 0px;        
            }
            a{
                padding: 20px;
                display: block;
                font-weight: $bold;
                color: $primaryColor;
                text-decoration: none !important;
                margin-bottom: 0;
                font-size: clamp(20px, 5vw, 24px);
                @include transition;
                @include smartphone{
                    text-align: center;
                    padding: 15px;
                }
            }
            .active{
                background-color: $primaryColor;
                color: #fff;

            }
        }
    
    }
}