.sejour{
    @include container;
    .content{
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        @include large{
            flex-direction: column-reverse;
        }

        &:nth-child(odd){
            flex-direction: row-reverse;
            @include large{
                flex-direction: column-reverse;
            }
        }
        .content-text{
            width: 42%;
            padding: 20px;
            padding-left: 0px;
            @include large{
                width: 100%;
            }
            p{
                margin: 20px 0;
                color: $primaryColor;
            }
            .btn{
                margin-top: 40px;
                display: block;
                margin-left: 0;
            }
        }
        .img{
            height: auto;
            background-size: cover;
            background-position: center;
            width: 50%;
            @include large{
                width: 100%;
                height: 400px;
            }
        }
    }
    
}