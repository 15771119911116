.info-carnet{
    @include container;
    .container{
        margin-top: 60px;
        margin-bottom: 150px;
        display: flex;
        align-items: center;
        @include large{
            flex-direction: column;
        }
        .img{
            position: relative;
            width: 55%;
            height: 500px;
            aspect-ratio: 2/1;
            background-size: cover;
            background-position: center;

            @include desktop{
                width: 45%;
                @include large{
                    width: 100%;
                    height: 400px;

                }
            }
            .photo{
                width: 260px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-size: cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -130px;
                border: 15px solid #fff;
                @include large{
                    right: -60px;
                    width: 180px;
                    border-width: 10px;
                }
            }
        }
        .content{
            position: relative;
            width: 25%;
            margin-left: 160px;
            color: $primaryColor;
            @include desktop{
                width: 35%;
                @include large{
                    width: 100%;
                    margin-left: 0;
                    margin-top: 100px;
                    text-align: center;
                }
            }
            .content-title{
                margin-bottom: 20px;
                h2,h3,p{
                    text-align: center;
                }
                p{
                    margin-top: 10px;
                    font-style: italic;
                }
            }
            &:before{
                content: "‘’";
                position: absolute;
                top: -80px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                font-size: 150px;
                overflow: visible;
                font-family: 'Abril Display', arial;
            }
            &:after{
                content: "‘’";
                position: absolute;
                bottom: -100px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                font-size: 150px;
                overflow: visible;
                font-family: 'Abril Display', arial;
                line-height: 0;
            }
        }
    }
}

.coup-de-coeur{
    background-color: $blueWhiteColor;
    padding: 60px 0;
    .container{
        @include container;
        display: flex;
        .content-text{
            width: 60%;
            color: $primaryColor;

            @include desktop-xl{
                width: 80%;
                @include large{
                    width: 100%;
                }
            }
            h2{
                font-family: 'MoonTime', arial;
                font-size: clamp(60px, 2vw,80px);
                text-transform: initial;
                font-weight: $regular;
                
            }
            h3{
                max-width: 500px;
                margin-top: 30px;
            }
            p{
                margin: 40px 0;
            }
            .container-img{
                display: flex;
                justify-content: space-between;
                .img{
                    width: calc(100% / 3 - 1em);
                    aspect-ratio: 1/1;background-size: cover;
                    @include tablet{
                        width: calc(100%/2 - 0.5em);
                        &:last-child{
                            display: none;
                        }
                    }
                }
            }
        }
        .container-diapo{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 20px;
            width: 60%;
            @include large{
                display: none;
            }
            .diapo{
                padding: 40px;
                width: 80%;
                background-color: #fff;
                text-align: center;
                color: $primaryColor;
                transform: rotate(-3deg);
                box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);
                @include desktop-xl{
                    width: 80%;
                    padding: 20px;
                    @include large{
                        display: none;
                    }
                }
                .img{
                    width: 100%;
                    aspect-ratio: 1.2/0.9;
                    background-size: cover;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:after{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        content: url(../images/heart.svg);
                        width: 100px;
                        height: 100px;
                        padding-top: 10px;
                        border-radius: 50%;
                        background-color: #fff;
                        opacity: 0.8;
                    }
                }
                h2{
                    font-weight: $regular;
                    font-size: clamp(18px, 2vw, 24px);
                    margin: 20px auto;
                    width: 90%;
                }
                p{
                    font-family: 'MoonTime', arial;
                    font-size: 3em;
                }
            }
        }
    }
}

.carnet-activites{
    @include container;
    margin: 100px auto;
    position: relative;
    @include desktop-xxl{

    }
    .container{
        .slider-carnet-activites{
            margin-top: 60px;
            display: flex;
            
            .item{
                width: 508px;
                margin-right: 20px;
                @include desktop{
                    width: 400px;
                    @include large{
                        width: 300px;
                    }
                }
                .container-img{
                    .img{
                        width: 100%;
                        aspect-ratio: 1/1;
                        background-size: cover;
                        margin-bottom: 20px;
                        position: relative;
                        .icon{  
                            height: 80px;
                            position: absolute;
                            bottom: -50px;
                            left: 50%;
                            transform: translateX(-50%);
                            @include desktop{
                                height: 50px;
                                bottom: -30px;
                            }
                        }
                    }
                    .stars{
                        display: flex;
                        justify-content: flex-end;
                        img{
                            margin-left: 5px;
                            height: 25px;
                            @include desktop{
                                height: 20px;
                            }
                        }
                    }
                }
                h3{
                    color: $primaryColor;
                    margin-bottom: 10px;
                    margin-top: 20px;
                    font-weight: $regular;
                    font-size: clamp(20px, 3vw, 26px);
                    width: 83%;
                    @include large{
                        width: 90%;
                    }
                }
                p{
                    width: 70%;
                    @include desktop{
                        width: 85%;
                    }
                }
            }
        }
    }
    .arrow-prev-activite{
        left: 60px;
        top: 40%;
        @include tablet{
            left: 20px;
        }
    }
    .arrow-next-activite{
        right: 60px;
        top: 40%;
        @include tablet{
            right: 20px;
        }
    }
}

.conseil{
    @include container;
    margin-bottom: 100px;
    text-align: center;
    .content-text{
        background-color: $blueWhiteColor;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        h2{
            margin-bottom: 20px;
        }
        p{
            max-width: 65%;
            margin: auto;
            @include large{
                max-width: 85%;
            }
        }
    }
}

.gallery-souvenir{
    @include container;
    text-align: center;
    h2{
        margin-bottom: 60px;
    }
}

.gallery-souvenir{
    position: relative;

    .arrow-down{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 50px;
        aspect-ratio: 1/1;
        bottom: -25px;
        left: 48.5%;
        border-radius: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-color: $primaryColor;
        transform: rotate(-90deg);
        @include transition;
        svg{
            width: 25px;
            height: 25px;
            @include transition;

        }
        &:hover{
            svg{
                height: 30px;
                width: 30px;
            }
        }
        
    }
    .arrow-gallery_open{
        svg{
            transform: rotate(180deg);
        }
    }

    .container{
        margin: 0px -10px;
        height: 60vh;
        overflow-y: hidden;
        border-bottom: 2px solid $primaryColor;
        position: relative;
        transition: .5s ease-in-out;
        &:after{    
            content: "";
            position: absolute;
            width: 100%;
            height: 50px;
            bottom: 0;
            left: 0;  
            background: rgb(255,255,255);
        }
        
    }
    .grid:after {
        content: '';
        display: block;
        clear: both;
      }
    .grid-sizer,
    .grid-item {
        width: calc(100%/3 - 20px);
        margin: 10px;

        @include large{
            width: calc(100%/2 - 20px);
        }
    }
    .grid-item {
        float: left;
      }
      
      .grid-item img {
        width: 100%;
      }
}

.btn-carnet{
    margin: auto;
    display: block;
    margin-top: 60px;
    margin-bottom: 80px;
}