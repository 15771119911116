.p-offre {
    font-size: 0.9rem
}

.btn-page-offre {
    transition: all 0.2s;
    min-width: 145px;
    min-height: 30px;
    text-transform: unset;
    padding: 7px 15px;
    margin: 0;
}

.btn-page-offre:hover p {
    display: none;
}

.btn-page-offre:hover:before {
    font-size: 0.9rem;
    line-height: 150%;
    content: "En savoir plus";
}

.btn-search {
    font-size: 16px;
    margin-top: 30px;
    vertical-align: 1%;
    min-width: 145px;
    min-height: 30px;
    text-transform: unset;
    padding: 7px 15px;
    margin: 0;
}

.label-offre {
    margin-top: 30px;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        background-size: .65em auto, 100%;
}

option {
    color: #d6f1fb;
}

.form-control-offre {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 2rem 0.375rem 0.5rem;
    min-width: 200px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0,99,163,1);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.label-filter {
    font-weight: bold;
    font-size : large;
    display: inline-block;
    margin-bottom: 0.5rem;
    text-align: left;
}

.grid-offres-moment {
    display: grid;  
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

@media (max-width: 852px) {
    .grid-offres-moment {
        display: grid;  
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .grid-image {
        aspect-ratio: 16/9;
    }
}

@media (min-width: 851px) and (max-width: 1275px) {
    .grid-offres-moment {
        display: grid;  
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}

@media (min-width: 1276px) {
    .grid-offres-moment {
        display: grid;  
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
}

.container-offre-moment {
    position: relative;
    padding: 60px 0px;
    background-color: #dbf0fa;
    text-align: center;
    @include large{
        padding: 40px 20px;
    }
}

.label-offre {
    text-align: left;
    margin-right: 4vh;
}

form label {
    display: inline-block !important;
}

.sub-container-offre {
    width:90vw; 
    margin: auto;
}

@media (min-width: 1700px) {
    .sub-container-offre {
       // width:60vw; 
    }
}

.content-offres {
    position: relative;
    background-color: white;
    margin : 0px;
    width: 100%;
    //min-height: 510px;
    //height: max-content;
    max-height: 510px;
}

.content-offres .content-offre-img {
        position: relative;
        overflow: hidden;
        height: 55%;
}

.content-offre-img h2 {
    z-index: 5;
    width: 100%;
    position: absolute;
    hyphens: auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content : center;
    line-height: unset;
    background-color: rgba(0, 99, 163,0.7);
    font-size: large;
    height: 60px;
    opacity: 0.9;
}

.img-offre {
    transition: all 0.4s;
    width: 100%;
    aspect-ratio: 1.5/1;
    background-size: cover;
    vertical-align: middle;
    height: 100%;
}

.img-offre:hover {
    transform: scale(1.1);
}

li::marker {
    color: rgba(0,99,163,1);
}

.content-text-offre {
    text-align: left;
    padding-left: 3vw;
    position: relative;
    height:50%;
    margin-bottom: 75px;
}

.content-text-offre ul {
    margin-top: 20px;
    list-style-type: disc;
    height:100%;
}

.content-text-offre ul li {
    width:100%;
}

.content-text-offre h3 {
    color: #0063a3;
}

.content-text-offre p {
    margin: 20px 0;
    margin-bottom: 40px;
}

.col-right {
    position: absolute;
    display: flex;
    bottom : 15px;
    right : 30px;
}

.col-left {
    position: absolute;
    max-width: 200px;
    bottom : 15px;
    left : 35px;
}
