/* ----------------------------------------------------------------
	Settings
-----------------------------------------------------------------*/

*, *:before, *:after{
    margin: 0;
    box-sizing: border-box;
    outline: none;
}

html, body{
	font-family: $font;
	overflow-x: hidden;
	scroll-behavior: smooth;
	font-weight: $light;
}

dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

::selection {
	background: $primaryColor;
	color: $whiteColor;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }

input{
	cursor: pointer;
}
option{
	color: #000 !important;
}
/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
	list-style: none; 
}
h1,h2,h3,h4,h5,h6{text-transform: uppercase;font-weight: normal;}
a { text-decoration: none !important;border: none; cursor: pointer;}
img { max-width: 100%; height: auto;border: none; }