.container-review{
    @include container;
    margin-top: 50px;
    position: relative;

    h2{
        text-align: center;
        color: $primaryColor;
    }

    .content-review{
        position: relative;
        display: flex;
        align-items: center;
        margin: 70px 0;

        @include desktop{
            margin: 100px 0;

            @include large{
                margin: 50px 0;
            }
        }

        @include large{
            flex-direction: column;
        }

        .slider-img-review{
            width: 78%;


            @include large{
                width: 100%;
            }
            .slick-list, .slick-track{
                margin-top: 0 !important;
            }
            div{
            display: flex;

            .img-review-personal{
                div{
                    width: 30%;
                    aspect-ratio: 1/1;
                    margin: 5px;
                    background-size: cover;
                    background-position: center;

                        @include large{
                            width: 30vw;
                        }
                        &:first-child{
                            margin-left:0;
                        }
                    }
                }
            } 
        }
    }
    .review{
        background-color: white;
        box-shadow: 10px 10px 18px 5px rgba(0,0,0,0.14);
        position: absolute;
        width: 35%;
        right: 0;
        padding: 40px 40px 80px 40px;

        @include desktop-xl{
            width: 50%;
            padding: 30px 30px 60px 30px;
             
            @include large{
                position: relative;
                width: 70%;
                margin-top: -70px;

                @include tablet{
                    width: 80%;
                    margin-top: -20px;

                    @include smartphone{
                        width: 100%;
                        padding: 20px;
                    }
                }
            }
        }
        .review-slider{
            width: 100%;

            @include desktop-xl{
                width: 90%;

                @include large{
                    width:70%;

                    @include tablet{
                        width: 80%;

                        @include smartphone{
                            width: 100%;
                            padding-bottom: 60px;
                        }
                    }
                }
            }
            .content-person{
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                h3{
                    color: $primaryColor;
                }
                .photo{
                    width: 100px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    margin-right: 20px;
                    background-size: cover;
                    background-position: center;

                    @include large{
                        width: 70px;
                    }
                }
            }
        }
        .controller-review{
            position: absolute;
            right: 40px;
            bottom: 20px;
            display: flex;

            @include large{
                right: 20px;
                bottom: 20px;
            }   

            .next-review{
                margin-left: 20px;
            }
        }
    }
}
