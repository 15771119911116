.intro-conseil{
    h1{
        font-weight: $regular;
    }
}
.conseils{
    padding: 60px 0;
    margin: 60px 0;
    .container{
        @include container;
        display: flex;
        .content-text{
            width: 60%;
            color: $primaryColor;

            @include desktop-xl{
                width: 80%;
                @include large{
                    width: 100%;
                }
            }
            h2{
                font-family: 'Cinderella', arial;
                font-size: clamp(60px, 2vw,80px);
                text-transform: initial;
                font-weight: $regular;
                
            }
            h3{
                max-width: 500px;
                margin-top: 30px;
            }
            p{
                margin: 40px 0 ;
            }
            .container-img{
                display: flex;
                justify-content: space-between;
                .img{
                    width: calc(100% / 3 - 1em);
                    aspect-ratio: 1/1;background-size: cover;
                    @include tablet{
                        width: calc(100%/2 - 0.5em);
                        &:last-child{
                            display: none;
                        }
                    }
                }
            }
        }
        .container-diapo{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 20px;
            width: 50%;
            @include desktop-xl{
                width: 80%;
                @include large{
                    display: none;
                }
            }
            .diapo{
                padding: 40px;
                padding-bottom: 0px !important;
                width: 80%;
                background-color: #fff;
                text-align: center;
                color: $primaryColor;
                transform: rotate(-3deg);
                box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);
                @include desktop-xl{
                    padding: 20px;
                    @include large{
                        display: none;
                    }
                }
                .img{
                    width: 100%;
                    aspect-ratio: 1.2/0.9;
                    background-size: cover;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:after{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        content: url(../images/heart.svg);
                        width: 100px;
                        height: 100px;
                        padding-top: 10px;
                        border-radius: 50%;
                        background-color: #fff;
                        opacity: 0.8;
                    }
                }
                h2{
                    font-weight: $regular;
                    font-size: clamp(18px, 2vw, 24px);
                    margin: 50px auto;
                    width: 90%;
                }
                p{
                    font-family: 'MoonTime', arial;
                    font-size: 3em;
                }
            }
        }
    }
    &:nth-child(odd){
        background-color: $blueWhiteColor;
        .container{
            flex-direction: row-reverse;
            .container-diapo{
                justify-content: flex-start;
            }
        }
    }
}