.guidventure{
    max-width: 90vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 80px auto;
    text-align: center;
    background-color: rgb(0, 99, 163);

    
    .img{
        background-position: center;
        width: 100% !important;
        aspect-ratio: 1.8/1;
        background-size: cover;
        background-repeat: no-repeat;
        max-height: 470px;
        opacity: 0.7;
        position: relative;
       
        //filter: brightness(70%);
        .content-text{
            background: rgba(0, 0, 0, 0);
            margin-left: auto;
            margin-right: auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 37vw;
            position: relative;
            padding: 30px;
            position: unset;

            .guiddiv{
                z-index: 20;
                margin-bottom: 20px;
                position: absolute; 
                top: 50%; 
                left: 50%; 
                transform: translate(-50%, -50%);
                span{
                    font-family: 'OoohBaby-Regular';
                    color:white;
                    margin-bottom: 11vh;
                    font-size: 32px;
                }
                .h2guid{
                    color:white;
                    margin: 0px 0px 0px 0px;
                    filter : brightness(1);
                    opacity: 1;

                }
            }
        }
        .btn-guide{
            position: absolute; 
            bottom: 10%; 
            left: 50%; 
            transform: translate(-50%, -50%);
        }
    }
 
    
}