.dropdown{
    @include container;
    position: relative;
    h1{
        color: $primaryColor;
    }
    .content-items{
        margin: 60px 0;
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;
        height: 650px;
        overflow-y: hidden;
        border-bottom: 2px solid $primaryColor;
        transition: height 1s ease;

        @include smartphone{
            margin-top: 30px;
            height: 520px;
        }
        .item{
            width: calc(100%/4 - 20px);
            margin: 10px;
            height: 570px;
            background-size: cover;
            position: relative;
            margin-bottom: 80px;

            @include desktop-xl{
                width: calc(100%/3 - 20px);
                margin-bottom: 50px;

                @include large{
                    width: calc(100%/2 - 20px);
                    margin-bottom: 30px;

                    @include smartphone{
                        width: calc(85% - 20px);
                        margin-left: auto;
                        margin-right: auto;
                        height: 470px;
                    }
                }
            }
            &:before{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: 0;
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);                    transition: 0.5s ease-in-out;
                z-index: 1; 
            }
            .info{
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: rgba(0, 99, 163, 0.6);
                width: 100%;
                height: 30%;
                transition: 0.4s ease-in-out;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;

            }
            &:hover{
                .btn{
                    opacity: 1;
                }
                .info{
                    height: 100%;
                }
            }
            
            h3{
                color: #fff;
                text-align: center;
                width: 80%;
                font-size: clamp(20px, 3vw, 26px);
                font-weight: $regular;
                transition: .4s ease-in-out;
                z-index: 999;

            }

            .btn{
                position: absolute;
                bottom: 10%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 99;
                opacity: 0;
                transition: .4s ease-in-out;
                margin: 0;
            }
        }
        
    }
    .arrow-down{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 40px;
        height: 40px;
        background-color: $primaryColor;
        position: absolute;
        transform: rotate(-90deg) translateX(-50%);
        transform-origin: 50% 50%;
        border-radius: 50%;
        bottom: 0;
        left: 48.75%;
        @include transition;

        svg{
            width: 30px;
            height: 30px;
            @include transition;
        }

        &:hover{
            padding: 7px !important;
            svg{
                height: 40px;
            }
        }
        &_open{
            svg{
                transform: rotate(180deg);
            }
        }
    }
}