.histoire{
    h1{
        margin-bottom: 10px
    }
    @include container;
    p{
        padding: 0.5em 0;
    }
}

.histoire-qualite{
    
    background-color: $blueWhiteColor;
    padding: 40px;
    margin: 80px 0;
    .container{
        @include container;
        display: flex;
        justify-content: space-evenly;

        @include desktop{
            justify-content: space-between;

            @include large{
                flex-direction: column;
            }
        }

        .item{
            width: 18%;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;

            @include desktop-xl{
                width: 25%;

                @include large{
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    margin: 20px 0px;
                    
                    &:nth-child(even){
                        flex-direction: row-reverse;

                        .content-text{
                            &:nth-child(even){
                                margin-right: 50px;
                                margin-left: unset;
                            }
                        }
                        .icon{
                            &:before{
                                transform: rotate(90deg) translateY(-50%);
                                bottom: unset;
                                top: 50%;
                                right: unset;
                                left: -39px;
                                @include smartphone{
                                    transform: unset;
                                    bottom: -27px;
                                    top: unset;
                                    left: unset;
                                }
                            }
                        }
                    }
                    }
                }
                @include smartphone{
                    flex-direction: column;
                    &:nth-child(even){
                        flex-direction: column;

                        .content-text{
                            &:nth-child(even){
                                margin-right: unset;
                            }
                        }
                }
            }

            .icon{
                position: relative;
                width: 248px;
                height: 248px;
                padding: 50px;
                border: 15px solid #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include desktop-xl{
                    width: 220px;
                    height: 220px;
                    padding: 40px;
                    border-width: 10px;

                    @include large{
                        width: 180px;
                        height: 180px;
                        padding: 30px;

                        @include smartphone{
                            width: 150px;
                            height: 150px;
                            margin-bottom: 40px;
                            border-width: 7px;
                        }
                    }
                }

                &:before{
                    position: absolute;
                    bottom:-30px;
                    content: '';
                    display: block;
                    background-color: $primaryColor;
                    width: 20px;
                    aspect-ratio: 1/1;
                    clip-path: polygon(100% 0, 0 0, 50% 100%);
                    z-index: 1;

                    @include large{
                        transform: rotate(-90deg) translateY(-50%);
                        bottom: unset;
                        top: 50%;
                        right: -39px;

                        @include smartphone{
                            transform: unset;
                            bottom: -27px;
                            top: unset;
                            right: unset;
                        }
                    }
                }
            }
            .content-text{
                @include large{
                    width: 55%;
                    text-align: left;
                    margin-left: 50px;
                    @include tablet{
                        width: 60%;

                        @include smartphone{
                            width: 100%;
                            margin-left: unset;
                            text-align: center;
                        }
                    }
                }
                h2{
                    margin: 30px 0;

                    @include large{
                        margin-bottom: 10px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.review-histoire{
    h2{
        text-align: center;
    }
    .slick-list{
        overflow: visible;
    }
    .container{
        padding: 60px 0;
        padding-top: 100px;
        display: flex;
        justify-content: space-evenly;
        position: relative;

        &:before{
            content: "";
            display: block;
            position: absolute;
            background-color: $primaryColor;
            width: 100vw;
            height: 50%;
            bottom: 0;
            z-index: -1;

        }
        .item{
            position: relative;
            background-color: #fff;
            padding: 30px 50px;
            width: 400px;
            min-height: 435px;
            color: $primaryColor;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);
            margin:0 20px;
            overflow: visible;

            @include tablet{
                width: 360px;
                padding: 20px 35px;
            }
            p{
                width: 90%;
                &:first-child{
                    font-weight: $bold;
                    margin-bottom: -30px;
                }
            }
            .img{
                display: block;
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-size: cover;
                position: absolute;
                top: -60px;
                left: 50%;
                transform: translateX(-50%);
                border: 5px solid #fff;
            }
            .row{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                .content{

                    .note{
                        display: flex;
                        p{
                            margin-bottom: -20px;
                            font-weight: $bold;
                            font-size: 126px;

                            &:last-child{
                                font-size: 63px;
                                margin-top: 30px;
                            }
                        }
                    }
                }
                
            }
            .stars{
                display: flex;
            }
        }
    }
}

.catchphrase{
    width: 60%;
    text-align: center;
    position: relative;
    margin: 200px auto;
    @include large{
        width: 80%;
        @include smartphone{
            width: 90vw;
            margin: 120px auto;
        }
    }
    h2{
        font-family: 'MoonTime', arial;
        font-size: clamp(70px, 6vw,130px);
        text-transform: initial;
        font-weight: $regular;
        @include smartphone{
            font-size: 39px;
        }
    }
    &:before{
        content: "‘’";
        display: block;
        font-family: 'Abril Display';
        font-size: 300px;
        color: $primaryColor;
        position: absolute;
        top: 20px;
        left: 43%;
        transform: translateY(-50%);
        margin-bottom: -200px;
        @include large{
            font-size: 200px;
            @include smartphone{
                font-size: 120px;
            }
        }
    }
    &:after{
        content: "‘’";
        display: block;
        font-family: 'Abril Display';
        font-size: 300px;
        color: $primaryColor;
        position: absolute;
        bottom: 0px;
        left: 43%;
        transform: translateY(-50%);
        margin-bottom: -520px;
        @include large{
            font-size: 200px;
            margin-bottom: -350px;
            @include smartphone{
                font-size: 120px;
                margin-bottom: -210px;
            }
        }
    }
}