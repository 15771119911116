.sur-mesure{
    @include container;
    p{
        width: 75%;
        margin-top: 30px
    }
}
.number{
    display: flex;
    margin-top: 15px;
    margin-right: 40px;
    input{
        border: 1px solid $primaryColor;
        padding: 5px 15px;
        width: 100px;
        font-size: 16px;
        margin: 0;
        border-radius: 0px !important;
    }
    .btn-number{
        background-color: $primaryColor;
        color: #fff;
        padding:5.25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include smartphone{
            padding:5px;
        }
        div{
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}
.form-sur-mesure, .form-agences{
    @include container;
    h2{font-size: clamp(20px, 3vw, 26px);text-transform:initial;margin: 30px 0;}
    
    h3{
        font-size: clamp(16px, 3vw, 18px);
        text-transform: initial;
        margin: 30px 0 15px 0;
    }
    .column{
        display: flex;
        flex-direction: column;
        align-items: center;
        .star{
            display: flex;
        }
    }
    select{
        position: relative;
        background-color: #fff;
        border: 1px solid $primaryColor;
        padding: 10px;
        font-size: 16px;
        font-family: 'Work Sans', arial;
        font-weight: 500;
        margin: 0;      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image:
        linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        linear-gradient(to right, $primaryColor, $primaryColor);
        background-position:
            calc(100% - 16px) calc(1em + 2px),
            calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size:
            5px 5px,
            5px 5px,
            2em 2.5em;
        background-repeat: no-repeat;
    }

    
    input[type=text],input[type=tel],input[type=mail],input[type=date],input[type=time]{
        width: 100%;
        padding: 12px 10px;
        margin: 8px 0;
        box-sizing: border-box;
        border: 1px solid $primaryColor;
        border-radius: 0px !important;
        font-family: 'Work Sans', arial;
        color: #000;
        @include transition;
        &:focus{
            box-shadow: 0px 0px 0px 1px $primaryColor;
        }
    }
    input[type="radio"] {
        margin-right: 5px;
        appearance: none;
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid $primaryColor;
        transition: .2s ease-in-out; /* Transition appliquée ici */
      }
      
      input[type="radio"]::before {
        content: "";
        position: absolute;
        top: 6px;
        left: 6px;
        width: 0px; /* Initialiser à 0 pour la transition */
        height: 0px; /* Initialiser à 0 pour la transition */
        background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
        border-radius: 50%;
        transition: .2s ease-in-out; /* Transition aussi ici */
      }
      
      input[type="radio"]:checked::before {
        top: -1px;
        left: -1px;
        width: 16px;
        height: 16px;
      } 
    .rectangle-blue{
        background-color: $primaryColor;
        width: fit-content;
        padding: 40px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        @include smartphone{
            flex-direction: column;
        }
        div{
            width: 45%;
            @include smartphone{
                width: 100%;
                &:nth-child(2){
                    margin-top: 20px;
                }
            }
        }
        p{
            margin-bottom: 10px;
        }
        .column{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .column{
            align-items: flex-start;
        }
        input[type="radio"] {
            border: 1px solid #fff;
          }
          
          input[type="radio"]::before {
            background-color: #fff; /* Assurez-vous que $primaryColor est défini */
          }
        label{
            margin-right: 10px;
            @include smartphone{
                margin-bottom: 10px;
            }
        }
        select{
            background-image:
            linear-gradient(45deg, transparent 50%, $primaryColor 50%),
            linear-gradient(135deg, $primaryColor 50%, transparent 50%),
            linear-gradient(to right, white, white);
            color: #000 !important;
        }
    }
    .container-number{
        margin-top: 50px;
        .content-number{
            display: flex;

            .content-text{

                .number{
                    display: flex;
                    margin-top: 15px;
                    margin-right: 40px;
                    input{
                        border: 1px solid $primaryColor;
                        padding: 5px 15px;
                        width: 42px;
                        font-size: 16px;
                        margin: 0;
                    }
                    .btn-number{
                        background-color: $primaryColor;
                        color: #fff;
                        padding: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        div{
                            cursor: pointer;
                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
    .form-row{
        div{
            display: flex;
            align-items: center;
            @include smartphone{
                flex-direction: column;
                align-items: flex-start;
                
            }
            .column{
                flex-direction: row;
                @include smartphone{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 5px;
                }
                label{
                    @include smartphone{
                        margin-left: 10px;
                    }
                }
            }
        }
        label{
            margin-right: 30px;
        }
        textarea{
            font-family: arial;
            width: 70%;
            @include smartphone{
                width: 100%;
            }
        }
        
    }
    .container-logement{
        .content{
            display: flex;
            flex-wrap: wrap;

            @include smartphone{
                flex-direction: column;
                align-items: flex-start;
            }
            .column{

                margin-right: 60px;
                @include large{
                    margin-top: 20px;

                    @include smartphone{
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                    }
                }
                input{
                    margin-right: 0;
                    margin-top: 10px;
                     @include smartphone{
                        margin-right: 10px;
                     }
                }
            }
        }
    }
    .container-accompagnement{
        .content{
            display: flex;
            margin-top: 30px;
            .column{
                margin-right: 60px;
                img{
                    max-width: unset;
                    height: 50%;
                }
            }
            input{
                margin: 10px 0;
            }
        }
    }
    .container-budget{
        input{
            width: 70%;
            @include smartphone{
                width: 100%;
            }
        }
    }

    .list-recontacter{
        .recontacter-line{
            position: relative;
            width: 70%;
            @include smartphone{
                width: 100%;
            }
            h2{
                text-transform: initial;
                border-bottom: 2px solid $primaryColor;
                padding-bottom: 20px;   
            }

            .cta{
                position: absolute;
                top: 53%;
                right: 0;
                width: 50px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-color: $primaryColor;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                span{
                    position: absolute;
                    display: block;
                    width: 25px;
                    height: 3px;
                    background-color: #fff;
                    @include transition;
                    &:nth-child(2){
                        transform: rotate(90deg);
                    }
                }
            }
            .open-list {
                span{
                    &:nth-child(2){
                        transform: rotate(180deg);
                    }
                }
            }
        }
        input[type=radio]{
            margin-top: 5px;
        }
        .wrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0;
            .column{
                align-items: flex-start;
                input, select{
                    width: 400px;
                }
                label{
                    font-weight: $bold;
                    margin:5px 0;
                }
                
            }
            p{
                font-size: 12px;
                font-style: italic;
                width: 400px;
                margin-top: 10px;
            }
        }
        .btn{
            display: block;
            margin: auto;
        }
    }

    .list-agences,.list-agences-carte{
        .agences-line{
            position: relative;
            width: 70%;
            @include smartphone{
                width: 100%;
            }
            h2{
                text-transform: initial;
                border-bottom: 2px solid $primaryColor;
                padding-bottom: 20px;   
            }

            .cta{
                position: absolute;
                top: 53%;
                right: 0;
                width: 50px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-color: $primaryColor;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                span{
                    position: absolute;
                    display: block;
                    width: 25px;
                    height: 3px;
                    background-color: #fff;
                    @include transition;

                    &:nth-child(2){
                        transform: rotate(90deg);
                    }
                }
            }
            .open-list {
                span{
                    &:nth-child(2){
                        transform: rotate(180deg);
                    }
                }
            }
            
        }
        
        .content{
            border: 1px solid $primaryColor;
            display: flex;
            width: 70%;
            height: 600px;
            @include desktop-xl{
                width: 80%;
                @include large{
                    width: 100%;
                    @include tablet{
                        height: 700px;
                        flex-direction: column;
                    }
                }
            }
            ul{
                background-color: $primaryColor;
                width: 30%;
                @include tablet{
                    width: 100%;
                }
                li{
                    display: flex;
                    justify-content: space-between;
                    padding: 20px;
                    cursor: pointer;
                    @include transition;

                    label{
                        margin-right: 10px;
                        color: #fff;
                        font-weight: $bold;
                    }
                    &:hover{
                        background-color: $blueWhiteColor;
                        label{
                            color: $primaryColor;
                        }
                        input[type="checkbox"] {
                            border: 1px solid $primaryColor;
                        }
                    }
                    input[type="checkbox"] {
                        appearance: none;
                        position: relative;
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        border-radius: 3px;
                        border: 1px solid #fff;
                        transition: .2s ease-in-out; /* Transition appliquée ici */
                        display: flex;
                      }
                      
                      input[type="checkbox"]::before {
                        content: "";
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: 0px; /* Initialiser à 0 pour la transition */
                        height: 0px; /* Initialiser à 0 pour la transition */
                        background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
                        border-radius: 2px;
                        transition: .2s ease-in-out; /* Transition aussi ici */
                      }
                      
                      input[type="checkbox"]:checked::before {
                        top: -1px;
                        left: -1px;
                        width: 20px;
                        height: 20px;
                      }
                }
                .active{
                    background-color: $blueWhiteColor;
                    label{
                        color: $primaryColor;
                    }
                    input[type="checkbox"] {
                        border: 1px solid $primaryColor;
                    }
                }
            }
            .content-text{
                width: 70%;
                position: relative;
                @include tablet{
                    width: 100%;
                }
                h3{
                    padding: 17.5px 60px;
                    color: $primaryColor;
                    margin-top: 0;
                    width: 100%;
                    border-bottom: 1px solid $primaryColor;
                }
                .column{
                    padding: 20px 60px;
                    align-items: flex-start;
                    @include tablet{
                        padding: 20px;
                    }
                    label{
                        margin-bottom: 20px;
                        font-weight: $bold;
                    }
                    select,input[type="text"],input[type="mail"],input[type="date"],input[type="time"],input[type="tel"]{
                        width: 70%;
                        margin-bottom: 30px;
                        @include large{
                            width: 100%;
                        }
                    }

                }
                .btn{
                    justify-content: flex-end;
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    margin: 0;
                    @include tablet{
                        bottom: -20px;
                    }
                }
            }
        }
    }
}
#continue-to-date, #continue-to-infos, .submit-continue {
    display: none; /* Cache les boutons par défaut */
}

/* Affiche uniquement le bouton pour continuer à la date par défaut */
#continue-to-date {
    display: block;
}