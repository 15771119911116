/* ----------------------------------------------------------------
	Variables
-----------------------------------------------------------------*/

//-------------------- Base Colors --------------------//

$primaryColor: #0063A3;
$blueWhiteColor: #DBF0FA;
$whiteColor: #ffffff;
$darkColor: #000000;
$grayColor: #F3F3F3;

//-------------------- Fonts-weight --------------------//

$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 700;
$black: 900;

//---------------------- mep ---------------------------//

$margin: 80px;
$padding: 30px 0px;

//------------------- transform ------------------------//

$transform: translate(-50%,-50%);

//-------------------- Font family ---------------------//

$font: "myriad-pro", sans-serif;

//---------------------- Container ---------------------//

$container: 1640px;



$menu: 10px;