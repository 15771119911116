@keyframes plane {
	0% {
		transform: rotate(0);
		transform-origin: right;
	}

	100% {
		transform: rotate(360deg);
		transform-origin: right;
	}
}