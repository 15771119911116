
.container-intro_cta{
    @include container;
    h1{
        color: $primaryColor;
        margin-bottom: 20px;
    }
    .cta{
        display: flex;
        justify-content: flex-start;
        margin: 60px 0;
        margin-bottom: 0;
        .btn{
            margin-left: 0;
            padding: 5px 20px;
            width: 230px;
            text-align: center;
        }
    }
}

//condition en js si + de 5 folder ->add class .container-page-folder sinon .container-page-folder_center
.container-page-folder{

    width: calc(100% - ((104vw - 1640px)/2));
    margin-left: auto;
    position: relative;
    padding: 60px 0;

    @include desktop-xxl{
        width: 95vw;
    }

    .slick-list{
        padding-left: 20px;
        margin-left: -20px;
    }
    .slider-page-folder{
        margin: 60px 0;
        display: flex;
 
        .item{
            width: fit-content;
            margin-right: 40px;
            @include tablet{
                margin-right: 20px;
            }
            div{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                
                label{
                    margin-right: 10px;
                    color: $primaryColor;
                    text-transform: uppercase;
                    font-weight: $bold;
                }

                input[type="checkbox"] {
                    appearance: none;
                    position: relative;
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    border-radius: 3px;
                    border: 1px solid $primaryColor;
                    transition: .2s ease-in-out; /* Transition appliquée ici */
                    display: flex;
                  }
                  
                  input[type="checkbox"]::before {
                    content: "";
                    position: absolute;
                    top: 7px;
                    left: 7px;
                    width: 0px; /* Initialiser à 0 pour la transition */
                    height: 0px; /* Initialiser à 0 pour la transition */
                    background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
                    border-radius: 2px;
                    transition: .2s ease-in-out; /* Transition aussi ici */
                  }
                  
                  input[type="checkbox"]:checked::before {
                    top: 2px;
                    left: 2px;
                    width: 10px;
                    height: 10px;
                  }
            }
            
            .img{
                margin: 20px 0;
                width: 270px;
                height: 370px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: fit-content;
                    width: inherit;
                    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);

                }
            }
            h3{
                color: $primaryColor;
                margin: auto;
                white-space: pre-wrap;
                text-align: center;
                min-height: 2.75em;
                width: 270px;
                font-size: clamp(18px, 3vw, 20px);
            }
            .btn{
                margin: auto;
                display: block;
                margin-top: 10px;
                font-size: 14px;
            }
            
        }
    }
    .arrow-next{
        right: 200px !important;
        top: 45%;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
        
        @include desktop-xxl{
            right: 100px !important;

            @include large{
                right: 20px !important;
            }
        }
    }
    .arrow-prev{
        top: 45%;
    }
}

// .container-page-folder_center{
//     padding: 60px 0;
//     h2{
//         @include container;
//     }
//     .slider-page-folder-centre{
//         @include container;
//         display: flex;
//         justify-content: center;
//         width: 100%;
//         height: 100%;
//         margin: 60px auto;
//     }
//     .item{
//         width: fit-content;
//         margin-right: 20px;
//         div{
//             display: flex;
//             justify-content: flex-end;
//             align-items: center;
            
//             label{
//                 margin-right: 10px;
//                 color: $primaryColor;
//                 text-transform: uppercase;
//                 font-weight: $bold;
//             }

//             input[type="checkbox"] {
//                 appearance: none;
//                 position: relative;
//                 width: 16px;
//                 height: 16px;
//                 margin-right: 5px;
//                 border-radius: 3px;
//                 border: 1px solid $primaryColor;
//                 transition: .2s ease-in-out; /* Transition appliquée ici */
//                 display: flex;
//               }
              
//               input[type="checkbox"]::before {
//                 content: "";
//                 position: absolute;
//                 top: 6px;
//                 left: 6px;
//                 width: 0px; /* Initialiser à 0 pour la transition */
//                 height: 0px; /* Initialiser à 0 pour la transition */
//                 background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
//                 border-radius: 2px;
//                 transition: .2s ease-in-out; /* Transition aussi ici */
//               }
              
//               input[type="checkbox"]:checked::before {
//                 top: 2px;
//                 left: 2px;
//                 width: 10px;
//                 height: 10px;
//               }
//         }
        
//         img{
//             margin: 30px 0;
//             width: 270px;
//             height: 370px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.147);
            
//         }
//         h3{
//             color: $primaryColor;
//             margin: auto;
//             white-space: pre-wrap;
//             text-align: center;
//             min-height: 2.75em;
//             width: 270px;
//             font-size: clamp(20px, 3vw, 26px);
//         }
//         .btn{
//             margin: auto;
//             display: block;
//             margin-top: 10px;
//             font-size: 14px;
//         }
//     }
// }
.blue{
    background-color: $blueWhiteColor;
}

.form-folder, .form-agences-contact{
    @include container;
    position: relative;
    margin: 60px auto;
    form{
        margin-top: 40px;
        .gender{
            div{
                display: flex;
                align-items: center;
            }
            label{
                font-weight: $regular;
            }
            input[type="radio"] {
                appearance: none;
                position: relative;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                border-radius: 50%;
                border: 1px solid $primaryColor;
                transition: .2s ease-in-out; /* Transition appliquée ici */
                display: flex;
                background-color: #fff;
              }
              
              input[type="radio"]::before {
                content: "";
                position: absolute;
                top: 6px;
                left: 6px;
                width: 0px; /* Initialiser à 0 pour la transition */
                height: 0px; /* Initialiser à 0 pour la transition */
                background-color: $primaryColor; /* Assurez-vous que $primaryColor est défini */
                border-radius: 50%;
                transition: .2s ease-in-out; /* Transition aussi ici */
              }
              
              input[type="radio"]:checked::before {
                top: 2px;
                left: 2px;
                width: 10px;
                height: 10px;
              }              
              
        }
        .wrap{  
            display: flex;
            flex-direction: row;
            column-gap: 100px;
            flex-wrap: wrap;
            margin-top: 20px;

            label{
                font-weight: bold;
            }
            input[type=text],input[type=tel],input[type=mail],input[type=date],input[type=time]{
                width: 100%;
                padding: 12px 10px;
                margin: 8px 0;
                box-sizing: border-box;
                border: 1px solid $primaryColor;
                color: #000;
                @include transition;
                &:focus{
                    box-shadow: 0px 0px 0px 1px $primaryColor;
                }
            }
            .column{
                display: flex;
                flex-direction: column;
                // width: calc(33% - 100px);
                width: 400px;
                @include desktop{
                    width: 350px;
                }
                @include tablet{
                    width: 800px;
                }
                
            }
        }
        .column-textarea{
            display: flex;
            flex-direction: column;
            width: calc(400px*2 + 100px);
            label{
                font-weight: bold;
                margin-bottom: 5px;
            }
            textarea{
                width: 100%;
                padding: 12px 10px;
                margin: 8px 0;
                box-sizing: border-box;
                border: 1px solid $primaryColor;
                @include transition;
                &:focus{
                    box-shadow: 0px 0px 0px 1px $primaryColor;
                }
            }
            p{
                margin-top: 10px;
                font-size: 12px;
                color: grey;
                font-style: italic;
            }
        }   
        .btn{
            display: block;
            margin: auto;
            margin-top: 40px;
        }    
    }
    img{
        position: absolute;
        bottom: -200px;
        right: 20px;
        z-index: -9999;
    }
}
.form-folder form .column-textarea textarea{
    width: 900px;
    @include desktop{
        width: 800px;
        @include large{
            width: 350px;
            @include tablet{
                width: 100%;
            }
        }
    }
}

